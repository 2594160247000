import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Center,
  Circle,
  HStack,
  Heading,
  Icon,
  Image,
  Link,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { RiUser2Fill } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import apiErrorHandler from '../api/apiErrorHandler';
import { socketPlayer } from '../api/socket';
import PlayNowBtnImg from '../assets/playnow.png';
import forecastImg from '../assets/FORECAST.png';
import trifectaImg from '../assets/TRIFECTA.png';
import Price from '../components/Price';
import {
  BETPLACEDGRAND,
  CURRENT_DECK_STATUS,
  MERCHANTID,
  PLAYERCOUNT,
  SITEID,
} from '../helpers/constants';
import useAuth from '../hooks/useAuth';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import PagcorLogo from '../components/PagcorLogo';
import { Link as ReactRouterLink } from 'react-router-dom';
import CashIn, { CashOut } from '../components/Buttons/CashButtons';
import CashButtons from '../components/Buttons/CashButtons';
import { PlayerCash } from '../components/Console/PlayerFund';

export default function PlayerDashboard() {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const [grand, setGrand] = useState({ total_prize: 0, possible_winner: 0 });
  const [mega, setMega] = useState({ total_prize: 0, possible_winner: 0 });
  const [history, setHistory] = useState([]);
  const [jackpot, setJackpot] = useState(false);
  const [event, setEvent] = useState();
  const [race, setRace] = useState();
  const navigate = useNavigate();
  const [onlineUsers, setOnlineUsers] = useState(1);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    async function getEventsInfo() {
      // const params = new URLSearchParams({ eventId, fightId: 0 })
      await axiosPrivate
        .get(`event/dashboard`, {
          signal: controller.signal,
        })
        .then(({ data }) => {
          isMounted &&
            data?.grand_jackpot_prize &&
            setGrand(data?.grand_jackpot_prize);
          isMounted &&
            data?.mega_jackpot_prize &&
            setMega(data?.mega_jackpot_prize);

          isMounted && setEvent(data?.ongoing_event);
          isMounted && setJackpot(data?.jackpot_open_bet);
          isMounted && setRace(data?.ongoing_race);
          isMounted &&
            Array.isArray(data?.declare_color) &&
            setHistory(data?.declare_color);
        })
        .catch(err => {
          const msg = apiErrorHandler(err);

          if (msg !== 'canceled') {
            console.error(msg);
          }
        })
        .finally();
    }

    getEventsInfo();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [axiosPrivate, refresh]);

  /* SOCKET */
  useEffect(() => {
    socketPlayer.io.opts.query = {
      ertyuhgvbniuhgbn: MERCHANTID,
      ertyuihgh: 1,
      msdfewaer: SITEID,
      usfsafsdnla: auth.user.username,
      isafdsafasp: auth.roles,
    };

    if (auth.user.username && auth.roles) {
      socketPlayer.open();
    }

    socketPlayer.on('connect', () => {
      // setSocketDisconnected(false);
      socketPlayer.sendBuffer = [];
    });

    socketPlayer.on('reconnecting', () => {
      // setRefreshBets(prev => !prev)
    });

    socketPlayer.on('connect_error', () => {
      // setSocketDisconnected(true);
    });

    socketPlayer.on(PLAYERCOUNT, data => {
      setOnlineUsers(data);
    });

    socketPlayer.on(BETPLACEDGRAND, () => {
      setRefresh(prev => !prev);
    });

    socketPlayer.on(CURRENT_DECK_STATUS, () => {
      setRefresh(prev => !prev);
    });

    return () => {
      socketPlayer.removeAllListeners();
      socketPlayer.disconnect();
    };
  }, [auth.user.username, auth.roles]);
  /* END SOCKET */

  return (
    <Box textAlign="center" pt={4} h={['full', 'calc(100vh - 60px)']}>
      <HStack justifyContent="center">
        <Icon as={RiUser2Fill} />{' '}
        <Text fontWeight="bold">
          <Text as="span" fontSize="lg">
            {onlineUsers}
          </Text>
        </Text>
      </HStack>

      <Price
        imgType="GRAND"
        amount={grand?.total_prize}
        winner={grand?.possible_winner}
      />

      <Price
        imgType="MAJOR"
        amount={mega?.total_prize}
        winner={mega?.possible_winner}
      />

      <Box mt={8}>
        <Heading>RACE HISTORY</Heading>
        <Heading as="h3" size="lg" color="goldenrod">
          {event?.event_name}
        </Heading>
        <HStack justifyContent="center" alignItems="flex-start">
          {history.map((item, index) => (
            <VStack key={`${index}-${item.fightNumber}`} gap={0}>
              <Box fontWeight="bold">{item.fightNumber}</Box>
              {item?.declare_win ? (
                <Circle
                  display="inline-flex"
                  cursor={'pointer'}
                  h="25px"
                  w="25px"
                  bg={`url(/150x150pxballs/BALLS-${item?.declare_win}.png)`}
                  overflow="hidden"
                  bgSize="contain"
                ></Circle>
              ) : (
                <Box w="25px"></Box>
              )}
            </VStack>
          ))}
        </HStack>
      </Box>
      <Box mt={4} mb={8}>
        <Text>Available Points:</Text>
        <Center mb={2}>
          <PlayerCash fontSize="2xl" />
        </Center>
        <CashButtons />
        {/* <Box
          as="button"
          bg="rgb(34,193,195)"
          bgImg="linear-gradient(318deg, #c32222 0%, #fdbb2d 100%)"
          border="0"
          borderRadius="xl"
          boxSizing="border-box"
          color="white"
          cursor="pointer"
          fontFamily='ui-sans-serif,system-ui,-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"'
          fontSize="1.125rem"
          fontWeight="bold"
          lineHeight="1.75rem"
          p="1rem 1.5rem"
          w={['70%', '300px']}
          textAlign="center"
          userSelect="none"
          _hover={{ boxShadow: 'none' }}
          sx={{ touchAction: 'manipulation' }}
          mb={4}
          onClick={() =>
            navigate(`/console/jackpot/${event?.id}`, {
              state: { event, race, grand },
            })
          }
        >
          Bet Grand Jackpot
        </Box> */}
        <Box mt={2} mb={2}>
          <Image src={forecastImg} m="auto" alt="FORECAST" w={['80%', 'sm']} />
          <Image src={trifectaImg} m="auto" alt="FORECAST" w={['80%', 'sm']} />
        </Box>

        <Image
          src={PlayNowBtnImg}
          alt="PLAYNOW"
          w="xs"
          h="90px"
          m="auto"
          cursor="pointer"
          onClick={() => navigate(`/console/${event?.id}/${race?.id}`)}
        />

        <Box paddingTop={4}>
          <Link
            as={ReactRouterLink}
            to={'/reports/events'}
            color="cyan"
            fontWeight="semibold"
            letterSpacing={2}
          >
            RACE EVENT HISTORY
          </Link>
        </Box>

        <Alert
          mt={4}
          status="warning"
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
        >
          <AlertIcon boxSize="40px" mr={0} />
          {/* <AlertTitle mt={4} mb={1} fontSize="lg">
            Application submitted!
          </AlertTitle> */}
          <AlertDescription mt={2} maxWidth="md">
            <Text as="b">Marble Color Race</Text> is for 21 years old and above
            only!
            <br />
            <Text as="b">ENJOY!</Text> but <Text as="b">PLAY RESPONSIBLY!</Text>
            <br />
            <br />
            For Assistance or Concerns, <Text as="b">Contact Us!</Text>
            <br />
            CSR1:{' '}
            <Link color="teal.500" href="tel:09180000000">
              09180000000
            </Link>
            <br />
            CSR2:{' '}
            <Link color="teal.500" href="tel:09170000000">
              09170000000
            </Link>
          </AlertDescription>
        </Alert>
      </Box>

      <PagcorLogo />
    </Box>
  );
}
