import {
  Button,
  Center,
  Circle,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Wrap,
  WrapItem,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import apiErrorHandler from '../../api/apiErrorHandler';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { CURRENT_DECK_STATUS } from '../../helpers/constants';
import { statusColor } from '../../helpers/statusColor';
import { useCallback } from 'react';

export default function UpdateButtons({
  eventId,
  currentRace,
  setCurrentRace,
  socketDeclarator,
  setRefreshHistory,
  eventRaceHistory,
}) {
  const axiosPrivate = useAxiosPrivate();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: cancellingIsOpen,
    onOpen: cancellingOnOpen,
    onClose: cancellingOnClose,
  } = useDisclosure();

  const currentRaceHistory = useMemo(() => {
    const crh = eventRaceHistory.find(
      item => item?.raceDetails?.f_no === currentRace?.fightNumber
    );
    return crh;
  }, [currentRace?.fightNumber, eventRaceHistory]);

  const handleStartNext = async status => {
    let raceNumber = currentRace.fightNumber;
    setIsLoading(true);

    if (status === 'NEXT') {
      raceNumber = Number(raceNumber) + 1;
    }

    if (typeof currentRace?.id === 'undefined') {
      raceNumber = currentRace.fightNumber || 1;
    }

    await axiosPrivate
      .post('declarator/race/new', {
        eventId,
        raceNumber,
        current_raceNumber: currentRace?.fightNumber,
      })
      .then(({ data }) => {
        socketDeclarator.emit(CURRENT_DECK_STATUS, {
          eventId,
          raceId: data?.raceId,
          status,
        });
        setRefreshHistory(prev => !prev);
        setCurrentRace(prev => ({
          ...prev,
          fightNumber: raceNumber,
          id: data?.raceId,
          status: 'PENDING',
        }));

        navigate(`/declarator/console/${eventId}/${raceNumber}`, {
          replace: true,
        });
      })
      .catch(err => {
        const msg = apiErrorHandler(err);

        if (msg === 'Race number is already in used') {
          const raceInfo = err.response?.data?.race_info;
          // setCurrentRace(prev => ({
          //   ...prev,
          //   fightNumber: raceNumber,
          //   id: raceInfo?.id,
          //   status: raceInfo?.status,
          // }));
          socketDeclarator.emit(CURRENT_DECK_STATUS, {
            eventId,
            raceId: raceInfo?.id,
            status,
          });

          navigate(`/declarator/console/${eventId}/${raceNumber}`, {
            replace: true,
          });
          return;
        }

        toast({
          title: 'Failed',
          description: apiErrorHandler(err),
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleButtonClick = status => {
    if (status === 'DONE') {
      onOpen();
      return;
    }

    if (status === 'CANCEL') {
      cancellingOnOpen();
      return;
    }

    handleSetStatus(status);
  };

  const handleSetStatus = async status => {
    setIsLoading(true);

    await axiosPrivate
      .put('declarator/event/status', {
        eventId,
        raceId: currentRace?.id,
        status,
      })
      .then(async ({ data }) => {
        if (status === 'CANCEL') {
          await axiosPrivate
            .post('declarator/race/declare', {
              raceId: currentRace?.id,
              declare_color: null,
            })
            .then(({ data }) => {
              toast({
                title: 'Success',
                description:
                  data?.msg || 'Successfully canceled. Rollback initiated.',
                status: 'success',
                duration: 9000,
                isClosable: true,
              });
            });
        }

        setRefreshHistory(prev => !prev);
        setCurrentRace(prev => {
          if (status === 'CANCEL') delete prev?.id;
          return {
            ...prev,
            status,
          };
        });

        socketDeclarator.emit(CURRENT_DECK_STATUS, {
          eventId,
          raceId: currentRace?.id,
          status,
        });
      })
      .catch(err => {
        const msg = apiErrorHandler(err);

        toast({
          title: 'Failed',
          description: msg,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      })
      .finally(() => {
        onClose();
        cancellingOnClose();
        setIsLoading(false);
      });
  };

  const shouldDisable = (btn, status) => {
    if (status === 'DONE') {
      return true;
    }

    if (status === 'DONE') return true;

    if (btn === 'OPEN') {
      if (
        status === 'OPEN' ||
        status === 'LOCK' ||
        status === 'DONE' ||
        status === 'CLOSE' ||
        status === 'CANCEL'
      ) {
        return true;
      }

      return false;
    }

    if (btn === 'PAUSE') {
      if (
        status === 'PAUSE' ||
        status === 'LOCK' ||
        status === 'DONE' ||
        status === 'CLOSE' ||
        status === 'CANCEL'
      ) {
        return true;
      }

      return false;
    }

    if (btn === 'LAST_CALL') {
      if (
        status === 'LAST_CALL' ||
        status === 'LOCK' ||
        status === 'DONE' ||
        status === 'CLOSE' ||
        status === 'CANCEL'
      ) {
        return true;
      }

      return false;
    }

    if (btn === 'LOCK') {
      if (
        status === 'LOCK' ||
        status === 'DONE' ||
        status === 'CLOSE' ||
        status === 'PENDING' ||
        status === 'CANCEL'
      ) {
        return true;
      }

      return false;
    }

    if (btn === 'CLOSE') {
      if (
        status === 'CLOSE' ||
        status === 'DONE' ||
        status === 'CLOSE' ||
        status === 'CANCEL' ||
        status === 'PENDING'
      ) {
        return true;
      }

      return false;
    }
    if (btn === 'DONE') {
      if (
        status === 'DONE' ||
        status === 'OPEN' ||
        status === 'PAUSE' ||
        status === 'LAST_CALL' ||
        status === 'LOCK' ||
        status === 'PENDING'
      ) {
        return true;
      }

      return false;
    }
  };

  return (
    <>
      <Wrap spacing="4" mb="5">
        <WrapItem w="90px">
          <Button
            w="full"
            size="sm"
            variant="solid"
            colorScheme="blue"
            onClick={handleStartNext.bind(
              this,
              typeof currentRace?.id === 'undefined' ? 'START' : 'NEXT'
            )}
            // isDisabled={disableNextButton}
            isDisabled={isLoading}
          >
            {typeof currentRace?.id === 'undefined' ? 'START' : 'NEXT'}
          </Button>
        </WrapItem>
      </Wrap>
      <Wrap spacing="4" mb="5">
        {[
          { label: 'OPEN', colorScheme: 'green' },
          { label: 'PAUSE', colorScheme: 'green' },
          { label: 'LAST_CALL', colorScheme: 'green' },
          { label: 'LOCK', colorScheme: 'orange' },
          { label: 'CLOSE', colorScheme: 'orange' },
          { label: 'CANCEL', colorScheme: 'red' },
          { label: 'DONE', colorScheme: 'blue' },
        ].map(item => (
          <WrapItem w="90px" key={item.label}>
            <Button
              w="full"
              size="sm"
              variant="solid"
              colorScheme={item.colorScheme}
              onClick={handleButtonClick.bind(this, item.label)}
              isDisabled={
                isLoading || shouldDisable(item.label, currentRace.status)
              }
            >
              {String(item.label || '').replace(/_/g, ' ')}
            </Button>
          </WrapItem>
        ))}
      </Wrap>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Are you sure to DONE the race?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Table>
              <Thead>
                <Tr>
                  <Th>Race #</Th>
                  <Th w="full">Race Results</Th>
                  {/* <Th textAlign="center">Win/Status</Th> */}
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>{currentRaceHistory?.raceDetails?.f_no}</Td>
                  <Td>
                    {(currentRaceHistory?.color || []).map(bola => (
                      <Circle
                        display="inline-flex"
                        cursor={'pointer'}
                        key={`pc-${currentRaceHistory?.raceDetails?.f_no}-${bola?.id}`}
                        h="25px"
                        w="25px"
                        m={1}
                        overflow="hidden"
                        bgImage={`/150x150pxballs/${bola?.color}-${bola?.label}.png`}
                        bgSize="contain"
                      ></Circle>
                    ))}
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </ModalBody>
          <ModalFooter>
            <Button
              isDisabled={isLoading}
              isLoading={isLoading}
              colorScheme="green"
              onClick={handleSetStatus.bind(this, 'DONE')}
              mr={4}
              minW={100}
            >
              {isLoading ? 'Please wait' : 'Confirm'}
            </Button>
            <Button colorScheme="blue" mr={3} onClick={onClose} minW={100}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={cancellingIsOpen} onClose={cancellingOnClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Are you sure you want to cancel the race?</Text>
          </ModalBody>
          <ModalFooter>
            <Button
              isDisabled={isLoading}
              isLoading={isLoading}
              colorScheme="red"
              onClick={handleSetStatus.bind(this, 'CANCEL')}
              mr={4}
              minW={100}
            >
              {isLoading ? 'Please wait' : 'Yes'}
            </Button>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={cancellingOnClose}
              minW={100}
            >
              No
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
