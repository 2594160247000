import numbro from 'numbro';

export default function commaSeparator(num = 0) {
  // const parts = num.toString().split('.');
  // parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  // parts[1] = parts?.[1] && String(parts?.[1]).slice(0, 2);
  // return parts?.[1] ? parts.join('.') : parts[0];

  if (!num) {
    return numbro(0).format({ thousandSeparated: true, mantissa: 2 });
  }

  return numbro(num).format({ thousandSeparated: true, mantissa: 2 });
}

export function commaSeparator2(num = 0) {
  // const parts = num.toString().split('.');
  // parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  // parts[1] = (parts?.[1] && String(parts?.[1]).slice(0, 2)) || '00';
  // return parts?.[1] ? parts.join('.') : parts[0];
  if (!num) {
    return numbro(0).format({ thousandSeparated: true, mantissa: 2 });
  }
  return numbro(num).format({ thousandSeparated: true, mantissa: 2 });
}
