const BetButtonColor = {
  RED: {
    bg: 'red',
    bola: {
      from: 'red.400',
      to: 'red',
    },
    textColor: {
      console: 'white',
      name: 'white',
      bola: 'white',
      report: 'red',
      win: 'white',
      shadow: 'red',
    },
    tapColor: 'white',
  },
  BLUE: {
    bg: 'blue',
    bola: {
      from: 'blue.500',
      to: 'blue',
    },
    textColor: {
      console: 'white',
      name: 'white',
      bola: 'white',
      report: 'blue',
      win: 'white',
      shadow: 'blue',
    },
    tapColor: 'white',
  },
  GREEN: {
    bg: 'green',
    bola: {
      from: 'green.400',
      to: 'green',
    },
    textColor: {
      console: 'white',
      name: 'white',
      bola: 'black',
      report: 'green',
      win: 'white',
      shadow: 'green',
    },
    tapColor: 'white',
  },
  YELLOW: {
    bg: 'yellow',
    bola: {
      from: 'yellow',
      to: 'yellow.500',
    },
    textColor: {
      console: 'black',
      name: 'black',
      bola: 'black',
      report: 'yellow.500',
      win: 'white',
      shadow: 'yellow',
    },
    report: 'pink.500',
    tapColor: 'black',
  },
  PINK: {
    bg: 'pink.400',
    bola: {
      from: 'pink.400',
      to: 'pink.500',
    },
    textColor: {
      console: 'black',
      name: 'black',
      bola: 'black',
      report: 'pink.500',
      win: 'white',
      shadow: 'pink',
    },
    tapColor: 'black',
  },
  WHITE: {
    bg: 'white',
    bola: {
      from: 'white',
      to: 'gray.100',
    },
    textColor: {
      console: 'black',
      name: 'gray.800',
      bola: 'black',
      report: 'initial',
      win: 'white',
      shadow: 'white',
    },
    tapColor: 'black',
  },
  MEGA_JACKPOT: {
    textColor: {
      name: 'black',
      bola: 'white',
      report: 'initial',
    },
  },
  MAJOR_JACKPOT: {
    textColor: {
      console: 'black',
      name: 'white',
      bola: 'white',
      report: 'initial',
    },
  },
};

export default BetButtonColor;
