import {
  Box,
  Center,
  Circle,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tr,
} from '@chakra-ui/react';

export default function Tally2({ raceHistory = [] }) {
  return (
    <Box my="2" w={['full', 'md']}>
      {/* <Heading as="h2" fontSize="md">
        TALLY
      </Heading> */}
      <TableContainer>
        <Table>
          <Tbody>
            <Tr>
              {raceHistory.map(item => (
                <Td
                  key={item?.button_color}
                  textAlign="center"
                  px={1}
                  border="3px solid goldenrod"
                >
                  <Center>
                    <Circle
                      h="25px"
                      w="25px"
                      bg={`url(/150x150pxballs/BALLS-${item?.button_color}.png)`}
                      overflow="hidden"
                      bgSize="contain"
                    ></Circle>
                  </Center>
                  <Text>
                    {item?.total_percentage || 0}
                    <Text as="span" fontSize="2xs">
                      %
                    </Text>
                  </Text>
                </Td>
              ))}
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
}
