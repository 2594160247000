import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  CloseButton,
  Flex,
  HStack,
  Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import apiErrorHandler from '../../api/apiErrorHandler';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import VideoPlayer from '../videoplayers/VideoPlayer';
import ButtonsAndDeclare from './ButtonsAndDeclare';
import CreateRace from './CreateRace';
import ExplodeEventButtons from './ExplodeEventButtons';
import Status from './Status';
import UpdateButtons from './UpdateButtons';
import History from './History';
import { socketDeclarator } from '../../api/socket';
import {
  CURRENT_DECK_STATUS,
  DECLARATOR_UPDATE_EVENT,
  DROPPED_BALL,
  MERCHANTID,
  SITEID,
} from '../../helpers/constants';
import useAuth from '../../hooks/useAuth';
import DeclareMegaGrand from './DeclareMegaGrand';

export default function DeclaratorConsole() {
  const { auth } = useAuth();
  const { eventId, raceNumber } = useParams();
  const axiosPrivate = useAxiosPrivate();
  const [eventInfo, setEventInfo] = useState({});
  const [currentRace, setCurrentRace] = useState({});
  const [eventButtons, setEventButtons] = useState([]);
  const [eventRaceHistory, setEventRaceHistory] = useState([]);
  const [refreshHistory, setRefreshHistory] = useState(false);
  const [apiErrorMsg, setApiErrorMsg] = useState(null);
  const [socketDisconnected, setSocketDisconnected] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    async function getEventsInfo() {
      setApiErrorMsg(null);
      // const params = new URLSearchParams({ eventId, fightId: 0 })
      await axiosPrivate
        .get(`declarator/event_list/${eventId}`, {
          signal: controller.signal,
        })
        .then(({ data }) => {
          if (typeof data?.data?.event === 'undefined') {
            throw new Error('Empty event details');
          }
          if (!Array.isArray(data?.data?.raceList)) {
            throw new Error('Race list expected to be an array');
          }

          if (data?.data?.raceList?.length > 0) {
            const raceList = data?.data?.raceList;

            const index = raceList.findIndex(
              item => Number(item.fightNumber) === Number(raceNumber)
            );

            if (index !== -1) {
              setCurrentRace(raceList?.[index]);
            } else {
              setCurrentRace(raceList?.[0]);
            }
          }

          isMounted && setEventInfo(data?.data?.event);
        })

        .catch(err => {
          let msg = apiErrorHandler(err);
          if (msg === 'canceled') return;

          setApiErrorMsg(msg);
        })
        .finally(() => {});
    }

    getEventsInfo();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [eventId, axiosPrivate, raceNumber, navigate]);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    async function getButtons() {
      const params = new URLSearchParams({
        event: eventId,
        race: currentRace?.id,
      });
      await axiosPrivate
        .get(`declarator/event/button`, { params, signal: controller.signal })
        .then(({ data }) => {
          if (Array.isArray(data?.data) && isMounted) {
            setEventButtons(data?.data);
          }
        })
        .catch(err => console.log(apiErrorHandler(err)));
    }

    currentRace?.id && eventId && getButtons();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [eventId, axiosPrivate, currentRace?.id]);

  // SOCKET
  useEffect(() => {
    socketDeclarator.io.opts.query = {
      ertyuhgvbniuhgbn: MERCHANTID,
      ertyuihgh: eventId,
      msdfewaer: SITEID,
      usfsafsdnla: auth.user.username,
      isafdsafasp: auth.roles,
    };

    socketDeclarator.open();

    socketDeclarator.on('connect', () => {
      setSocketDisconnected(false);
      socketDeclarator.sendBuffer = [];
    });

    socketDeclarator.on('connect_error', () => {
      setSocketDisconnected(true);
    });

    // socketDeclarator.on(DROPPED_BALL, () => {
    //   setRefreshPickList(prev => !prev);
    // });

    // socketDeclarator.on(CURRENT_DECK_STATUS, data => {
    //   setRefreshEventInfo(prev => !prev);
    //   console.log(data);
    // });

    // socketDeclarator.on(DECLARATOR_UPDATE_EVENT, () => {
    //   setRefreshHistoryList(prev => !prev);
    // });

    return () => {
      socketDeclarator.removeAllListeners();
      socketDeclarator.disconnect();
    };
  }, [eventId, auth?.roles, auth?.user?.username]);

  return (
    <Box pt={5} px={5} pb={10} h="full">
      {apiErrorMsg ? (
        <Alert status="error">
          <AlertIcon />
          <Box flex="1">{apiErrorMsg}</Box>
          <CloseButton
            alignSelf="flex-start"
            position="relative"
            right={-1}
            top={-1}
            onClick={() => setApiErrorMsg(null)}
          />{' '}
        </Alert>
      ) : null}

      <HStack w={'full'}>
        <Text as="b">{eventInfo.event_name}</Text> |{' '}
        <Text as="i">{eventInfo.arena_name}</Text>{' '}
      </HStack>

      <Flex gap={4}>
        <Box w={['full', '70%']}>
          <Box bg="gray.400" w={'full'} mb={'4'} h={['230px', '500px']}>
            <VideoPlayer
              videoType={eventInfo?.video_type}
              videoSrc={eventInfo?.video}
            />
          </Box>
          <History
            eventId={eventId}
            setEventRaceHistory={setEventRaceHistory}
            refreshHistory={refreshHistory}
            eventButtons={eventButtons}
          />
        </Box>
        <Box w={['full', '30%']}>
          <CreateRace
            eventId={eventId}
            currentRaceNumber={currentRace?.fightNumber}
            currentRace={currentRace}
            setCurrentRace={setCurrentRace}
            socketDeclarator={socketDeclarator}
          />
          <Status {...currentRace} />
          <UpdateButtons
            eventId={eventId}
            currentRace={currentRace}
            setCurrentRace={setCurrentRace}
            socketDeclarator={socketDeclarator}
            setRefreshHistory={setRefreshHistory}
            eventRaceHistory={eventRaceHistory}
          />

          <ExplodeEventButtons
            eventButtons={eventButtons}
            currentRace={currentRace}
            eventRaceHistory={eventRaceHistory}
            setRefreshHistory={setRefreshHistory}
            socketDeclarator={socketDeclarator}
          />

          <ButtonsAndDeclare
            eventId={eventId}
            currentRace={currentRace}
            setCurrentRace={setCurrentRace}
            eventButtons={eventButtons}
            socketDeclarator={socketDeclarator}
            setRefreshHistory={setRefreshHistory}
          />

          <DeclareMegaGrand
            eventId={eventId}
            currentRace={currentRace}
            eventRaceHistory={eventRaceHistory}
            socketDeclarator={socketDeclarator}
          />
        </Box>
      </Flex>

      {/* SOCKET AREA */}
      {socketDisconnected ? (
        <Alert status="error" pos="fixed" bottom="0" left="0">
          <AlertIcon />
          <AlertTitle>DISCONNECTED FROM SOCKET SERVER</AlertTitle>
          <AlertDescription>
            Please contact tech support, this will affect Viewers.
          </AlertDescription>
        </Alert>
      ) : null}
    </Box>
  );
}
