import { ArrowBackIcon } from "@chakra-ui/icons";
import { Alert, AlertDescription, AlertIcon, AlertTitle, Button, Container, Icon } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export default function Unauthorized() {
    const navigate = useNavigate()

    return <Container maxWidth={'container.lg'} pt={'4'}>
        <Alert
            status='warning'
            variant='subtle'
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
            textAlign='center'
            height='250px'
        >
            <AlertIcon boxSize='40px' mr={0} />
            <AlertTitle mt={4} mb={1} fontSize='lg'>
                UNAUTHORIZED
            </AlertTitle>
            <AlertDescription maxWidth='sm' mb={'4'}>
                You're not allowed to access this page.
            </AlertDescription>
            <Button size={'sm'} rightIcon={<ArrowBackIcon />} colorScheme='blue' variant='outline' onClick={() => navigate(-1)} mb={'4'}>
                Go Back
            </Button>
            <Button colorScheme='red' variant='link' onClick={() => navigate('/login', { replace: true, relative: 'path' })}>
                Login
            </Button>
        </Alert>
    </Container>
}