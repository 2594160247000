import { Flex, Text } from '@chakra-ui/react';

export default function Status({ status, declare_win }) {
  return (
    <Flex alignItems="flex-end" mb="2">
      <Text mr={2}>Status:</Text>

      {declare_win ? (
        <Text as="b" fontSize="lg">
          {declare_win} WIN
        </Text>
      ) : (
        <Text as="b" fontSize="lg">
          {String(status || '').replace(/_/g, ' ')}
        </Text>
      )}
    </Flex>
  );
}
