import {
  Box,
  Center,
  Circle,
  Heading,
  Table,
  Tbody,
  Text,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import Tally from './Tally';

export default function RaceHistory({ eventId, refreshRaceInfo }) {
  const axiosPrivate = useAxiosPrivate();
  const [raceHistory, setRaceHistory] = useState([]);

  function fontColor(color) {
    if (color === 'BLUE' || color === 'RED' || color === 'GREEN')
      return 'white';
    return 'black';
  }

  const colors = status => {
    let color = 'black';
    switch (status) {
      case 'OPEN':
        color = 'blue';
        break;
      case 'DONE':
      case 'CLOSE':
      case 'CANCEL':
      case 'PENDING':
        color = 'red';
        break;

      default:
        color = 'black';
        break;
    }

    return color;
  };

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    async function getEventsInfo() {
      const params = new URLSearchParams({ event: eventId });

      await axiosPrivate
        .get('event/pick_color', { params, signal: controller.signal })
        .then(({ data }) => {
          isMounted && setRaceHistory(data?.data || []);
        })
        .catch(err => {
          console.error(
            err.response?.data?.msg || 'Failed to retrieve event details.'
          );
        });
    }

    eventId && getEventsInfo();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [eventId, refreshRaceInfo, axiosPrivate]);

  return (
    <Box mt="2" px={2}>
      {/* <Tally raceHistory={raceHistory} /> */}
      <Heading as="h2" fontSize="md">
        RACE HISTORY
      </Heading>

      <Box>
        <table width="100%">
          <thead>
            <tr>
              <th
                style={{
                  borderCollapse: 'collapse',
                  borderWidth: '3px',
                  borderColor: 'goldenrod',
                  borderStyle: 'solid',
                }}
              >
                No.
              </th>
              <th
                style={{
                  borderCollapse: 'collapse',
                  borderWidth: '3px',
                  borderColor: 'goldenrod',
                  borderStyle: 'solid',
                  width: '100%',
                }}
              >
                Race Results
              </th>
              <th
                style={{
                  borderCollapse: 'collapse',
                  borderWidth: '3px',
                  borderColor: 'goldenrod',
                  borderStyle: 'solid',
                  whiteSpace: 'nowrap',
                }}
              >
                Wins
              </th>
            </tr>
          </thead>
          <tbody>
            {raceHistory.map((item, index) => (
              <tr key={`outer-${item?.raceDetails?.f_no}`}>
                <td
                  style={{
                    borderCollapse: 'collapse',
                    borderWidth: '3px',
                    borderColor: 'goldenrod',
                    borderStyle: 'solid',
                    paddingLeft: '5px',
                  }}
                >
                  <Text fontWeight="bold">{item?.raceDetails?.f_no}</Text>
                </td>
                <td
                  w="full"
                  style={{
                    borderCollapse: 'collapse',
                    borderWidth: '3px',
                    borderColor: 'goldenrod',
                    borderStyle: 'solid',
                  }}
                >
                  <Box p={1}>
                    {(item?.color || []).map(bola => (
                      <Circle
                        display="inline-flex"
                        cursor={'pointer'}
                        key={`pc-${item?.raceDetails?.f_no}-${bola?.id}`}
                        h="25px"
                        w="25px"
                        m={1}
                        // bg={bola?.color}
                        // borderColor="gray.200"
                        // borderWidth={'1px'}
                        overflow="hidden"
                        bgImage={`/150x150pxballs/${bola?.color}-${bola?.label}.png`}
                        bgSize="contain"
                      >
                        {/* <Text color={fontColor(bola?.color)}>
                          {bola?.label}
                        </Text> */}
                      </Circle>
                    ))}
                  </Box>
                </td>
                <td
                  style={{
                    borderCollapse: 'collapse',
                    borderWidth: '3px',
                    borderColor: 'goldenrod',
                    borderStyle: 'solid',
                  }}
                >
                  <Center width="full">
                    {/* {item?.raceDetails?.declare_win?.color &&
                      (
                        item?.color?.slice(0, Math.min(3, item.color.length)) ||
                        []
                      ).map(bola => (
                        <Circle
                          display="inline-flex"
                          cursor={'pointer'}
                          key={`pc-${item?.raceDetails?.f_no}-${bola?.id}`}
                          h="25px"
                          w="25px"
                          m={1}
                          overflow="hidden"
                          bgImage={`/150x150pxballs/${bola?.color}-${bola?.label}.png`}
                          bgSize="contain"
                        ></Circle>
                      ))} */}
                    {item?.raceDetails?.declare_win?.color ? (
                      <Circle
                        cursor={'pointer'}
                        key={`pc-${item?.raceDetails?.f_no}-${index}`}
                        h="25px"
                        w="25px"
                        // bg={item?.raceDetails?.declare_win}
                        // borderColor="gray.200"
                        // borderWidth={'1px'}
                        bgImage={`/150x150pxballs/BALLS-${item?.raceDetails?.declare_win?.color}.png`}
                        bgSize="contain"
                      ></Circle>
                    ) : null}
                  </Center>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
    </Box>
  );
}

function StatusLabel({ status }) {
  if (status === 'LOCK' || status === 'DONE') {
    return 'CLOSE';
  }

  return status;
}
