import localforage from 'localforage';
import { createContext, useEffect, useState } from 'react';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({});

  useEffect(() => {
    async function getLocalUser() {
      const user = await localforage.getItem('online_user');
      const token = await localforage.getItem('online_token');
      const refreshToken = await localforage.getItem('online_refreshToken');
      const roles = await localforage.getItem('online_roles');
      const points = await localforage.getItem('online_points');
      const account = await localforage.getItem('online_account');

      setAuth(prev => ({
        points,
        ...prev,
        user,
        token,
        refreshToken,
        roles,
        account,
      }));
    }

    getLocalUser();
  }, []);

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
