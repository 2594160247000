import { VStack } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useState } from 'react';
import raceStartImg from '../../../assets/RACE_START.png';

export default function StartImg({ duration = 2000 }) {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    // Set a timer to hide the component after the specified duration
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, duration);

    // Cleanup the timer on component unmount or when duration changes
    return () => clearTimeout(timer);
  }, [duration]);

  if (!isVisible) {
    return null; // If not visible, return null to render nothing
  }

  return (
    <VStack
      h="full"
      w="full"
      bgColor="blackAlpha.100"
      pos="absolute"
      top={0}
      justifyContent="center"
    >
      <VStack
        h={['60%', '350px']}
        w={['60%', '350px']}
        bgImage={`url(${raceStartImg})`}
        bgPosition="center"
        bgRepeat="no-repeat"
        bgSize="contain"
        justifyContent="flex-end"
      ></VStack>
    </VStack>
  );
}
