import { Text, VStack } from '@chakra-ui/react';
import imgWin from '../../../assets/YOUWIN.png';
import commaSeparator from '../../../helpers/commaSeparator';

export default function WinImg({ didWin }) {
  return (
    <VStack
      h="full"
      w="full"
      bgColor="blackAlpha.100"
      pos="absolute"
      top={0}
      justifyContent="center"
    >
      <VStack
        h={['60%', '350px']}
        w={['60%', '350px']}
        bgImage={`url(${imgWin})`}
        bgPosition="center"
        bgRepeat="no-repeat"
        bgSize="contain"
        justifyContent="flex-end"
      >
        <Text fontSize={['lg', '4xl']} pb={['5', '15%']} fontWeight="bold">
          {commaSeparator(didWin?.winning_amount)}
        </Text>
      </VStack>
    </VStack>
  );
}
