import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  HStack,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SkeletonText,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useMediaQuery,
  useRadioGroup,
} from '@chakra-ui/react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import GenerateCircle from '../../components/GenerateCircle';
import RadioCard from '../../components/RadioCard';
import BetButtonColor from '../../helpers/betButtonColor';
import commaSeparator from '../../helpers/commaSeparator';
import {
  BETHISTORYTYPE as betOptions,
  FILTEROPTIONS as options,
} from '../../helpers/constants';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

export default function BetReports() {
  const axiosPrivate = useAxiosPrivate();
  const [isLoading, setIsLoading] = useState(true);
  const [selected, setSelected] = useState();
  const [list, setList] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLargerThan800] = useMediaQuery('(min-width: 800px)');
  const [betType, setBetType] = useState(betOptions[0]);
  const [filter, setFilter] = useState(options[0]);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState({
    page_prev: null,
    next_page: null,
  });

  const handleShowDetails = data => {
    setSelected(data);
    onOpen();
  };

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'recordLimit',
    defaultValue: 'Today',
    onChange: setFilter,
  });

  const {
    getRootProps: getRootPropsBetType,
    getRadioProps: getRadioPropsBetType,
  } = useRadioGroup({
    name: 'betType',
    defaultValue: betOptions[0],
    onChange: setBetType,
  });

  const group = getRootProps();
  const groupBet = getRootPropsBetType();

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    async function getList() {
      let _filter = 1;
      let url = 'bet';

      switch (filter) {
        case options[0]:
          _filter = 1;
          break;
        case options[1]:
          _filter = 2;
          break;
        case options[2]:
          _filter = 3;
          break;
        default:
          _filter = 1;
          break;
      }

      switch (betType) {
        case betOptions[0]:
          url = 'bet';
          break;
        case betOptions[1]:
          url = 'bet/mega_jackpot';
          break;
        case betOptions[2]:
          url = 'bet/grand_jackpot';
          break;
        default:
          url = 'bet';
          break;
      }

      const params = new URLSearchParams({ page, filter: _filter });

      await axiosPrivate
        .get(url, { params, signal: controller.signal })
        .then(({ data }) => {
          const { msg, ...pagination } = data;
          if (Array.isArray(msg)) {
            isMounted && setList(msg);
          }
          isMounted &&
            setPagination({
              page_prev: pagination?.page_prev,
              next_page: pagination?.next_page,
            });
        })
        .catch(err => {
          console.error(
            err.response?.data?.msg || 'Failed to retrieve records.'
          );

          setList([]);
          isMounted &&
            setPagination({
              page_prev: null,
              next_page: null,
            });
        })
        .finally(() => setIsLoading(false));
    }

    getList();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [axiosPrivate, filter, page, betType]);

  return (
    <>
      <Box p={4}>
        <Heading as="h1" fontSize="lg">
          Bet History
        </Heading>

        {isLoading ? (
          <SkeletonText mt="4" noOfLines={4} spacing="4" skeletonHeight="2" />
        ) : null}

        <HStack
          {...groupBet}
          my={2}
          justifyContent={['flex-start', 'flex-end']}
        >
          {betOptions.map(value => {
            const radio = getRadioPropsBetType({ value });
            return (
              <RadioCard key={value} {...radio}>
                {value}
              </RadioCard>
            );
          })}
        </HStack>

        <HStack {...group} my={4} justifyContent={['flex-start', 'flex-end']}>
          {options.map(value => {
            const radio = getRadioProps({ value });
            return (
              <RadioCard key={value} {...radio}>
                {value}
              </RadioCard>
            );
          })}
        </HStack>

        {betType === betOptions[0] && isLargerThan800 ? (
          <Wide list={list} />
        ) : null}
        {(betType === betOptions[1] || betType === betOptions[2]) &&
        isLargerThan800 ? (
          <WideForGrand list={list} />
        ) : null}
        {isLargerThan800 ? null : (
          <Card list={list} handleShowDetails={handleShowDetails} />
        )}
        {list.length <= 0 ? (
          <Box mt={4} textAlign="center">
            No record.
          </Box>
        ) : null}

        <Box mt={4} textAlign="end">
          <Button
            leftIcon={<ChevronLeftIcon />}
            colorScheme="teal"
            variant="outline"
            size="sm"
            mr={2}
            isDisabled={!pagination?.page_prev}
            onClick={() => setPage(pagination?.page_prev)}
          >
            Prev
          </Button>
          <Button
            rightIcon={<ChevronRightIcon />}
            colorScheme="teal"
            variant="outline"
            size="sm"
            isDisabled={!pagination?.next_page}
            onClick={() => setPage(pagination?.next_page)}
          >
            Next
          </Button>
        </Box>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} size="sm">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader pb="1">Transaction Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              <Text as="span" fontWeight="bold" fontSize="sm">
                Event Name:
              </Text>{' '}
              {selected?.event_name}
            </Text>
            <Text>
              <Text as="span" fontWeight="bold" fontSize="sm">
                Date/Time:
              </Text>{' '}
              {moment(selected?.transaction_date).format('MMM D, YYYY, h:mm a')}
            </Text>
            {betType === betOptions[0] ? (
              <>
                <Text>
                  <Text as="span" fontWeight="bold" fontSize="sm">
                    Bet Type:
                  </Text>{' '}
                  {selected?.bet_type}
                </Text>
                <Text>
                  <Text as="span" fontWeight="bold" fontSize="sm">
                    Race #:
                  </Text>{' '}
                  {selected?.fightNumber}
                </Text>
                <Text
                  color={BetButtonColor?.[selected?.bet]?.textColor?.report}
                >
                  <Text
                    as="span"
                    fontWeight="bold"
                    fontSize="sm"
                    color="initial"
                  >
                    Bet on:
                  </Text>{' '}
                  {selected?.bet}{' '}
                  {/* {selected?.bet === 'MEGA_JACKPOT' ? null : selected?.label} */}
                </Text>
              </>
            ) : null}

            {betType === betOptions[1] || betType === betOptions[2] ? (
              <>
                <Box>
                  <Text as="span" fontWeight="bold" fontSize="sm">
                    Bet On:
                  </Text>
                  <HStack>
                    <GenerateCircle data={selected?.bet_color} />
                  </HStack>
                </Box>
              </>
            ) : null}

            {selected?.amount ? (
              <Text>
                <Text as="span" fontWeight="bold" fontSize="sm">
                  Amount:
                </Text>{' '}
                {commaSeparator(selected?.amount)}
              </Text>
            ) : null}

            <Text>
              <Text as="span" fontWeight="bold" fontSize="sm">
                Win/Loss:
              </Text>{' '}
              {selected?.win_status}
            </Text>
            <Text>
              <Text as="span" fontWeight="bold" fontSize="sm">
                Winning Amount:
              </Text>{' '}
              {selected?.winning_amount
                ? commaSeparator(selected?.winning_amount)
                : null}
            </Text>
            {selected?.status ? (
              <Text>
                <Text as="span" fontWeight="bold" fontSize="sm">
                  Status:
                </Text>{' '}
                {selected?.status}
              </Text>
            ) : null}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

const winStatusColor = status => {
  if (status === 'LOSS' || status === 'ROLLBACK') return 'red.500';
  if (status === 'WIN') return 'green.500';
  return 'initial';
};

function Wide({ list }) {
  return (
    <Table color="white">
      <Thead>
        <Tr>
          <Th>Date/Time</Th>
          <Th>Event Name</Th>
          <Th>Bet Type</Th>
          <Th>Race #</Th>
          <Th>Bet On</Th>
          <Th>Amount</Th>
          <Th>Win/Loss</Th>
          <Th>Winning Amount</Th>
        </Tr>
      </Thead>
      <Tbody>
        {list.map(item => (
          <Tr>
            <Td>
              {moment(item?.transaction_date).format('MMM D, YYYY, h:mm a')}
            </Td>
            <Td>{item?.event_name}</Td>
            <Td>{item?.bet_type}</Td>
            <Td>{item?.fightNumber}</Td>
            <Td color={BetButtonColor?.[item?.bet]?.textColor?.report}>
              {item?.bet}{' '}
              {/* {item?.bet === 'MEGA_JACKPOT' || item?.bet === 'MAJOR_JACKPOT'
                ? null
                : item?.label} */}
            </Td>
            <Td>{item?.amount ? commaSeparator(item?.amount) : null}</Td>
            <Td color={winStatusColor(item?.win_status)}>{item?.win_status}</Td>
            <Td>
              {item?.winning_amount
                ? commaSeparator(item?.winning_amount)
                : null}
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}

function WideForGrand({ list }) {
  return (
    <Table>
      <Thead>
        <Tr>
          <Th>Date/Time</Th>
          <Th>Event Name</Th>
          <Th>Bet On</Th>
          <Th>Win/Loss</Th>
          <Th>Winning Amount</Th>
        </Tr>
      </Thead>
      <Tbody>
        {list.map(item => (
          <Tr key={item?.transaction_date}>
            <Td>
              {moment(item?.transaction_date).format('MMM D, YYYY, h:mm a')}
            </Td>
            <Td>{item?.event_name}</Td>
            <Td>
              <HStack>
                <GenerateCircle data={item?.bet_color} />
              </HStack>
            </Td>
            <Td color={winStatusColor(item?.win_status)}>{item?.win_status}</Td>
            <Td>
              {item?.winning_amount
                ? commaSeparator(item?.winning_amount)
                : null}
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}

function Card({ list = [], handleShowDetails }) {
  return list.map(item => (
    <Box
      key={item?.id}
      borderBottom="1px"
      p={2}
      borderBottomColor="gray.200"
      onClick={handleShowDetails.bind(this, item)}
    >
      <HStack>
        <Box flex={1}>
          <Text fontWeight="bold">{item?.event_name}</Text>
          <Text fontSize="xs" color="gray.600">
            {moment(item?.transaction_date).format('MMM D, YYYY, h:mm a')}
          </Text>
        </Box>
        <Box>
          <Text
            color={
              item?.win_status === 'LOSS'
                ? 'red.500'
                : item?.win_status === 'WIN'
                ? 'green.500'
                : 'initial'
            }
            fontWeight="bold"
          >
            {item?.win_status === 'LOSS'
              ? '-'
              : item?.win_status === 'WIN'
              ? '+'
              : null}
            {item?.amount}
          </Text>
        </Box>
      </HStack>
    </Box>
  ));
}
