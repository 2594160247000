import { Text, VStack } from '@chakra-ui/react';
import commaSeparator from '../../../helpers/commaSeparator';
import imgWinMajor from '../../../assets/YOUWIN_MAJOR.png';
import imgWinGrand from '../../../assets/YOUWIN_GRAND.png';

export default function GrandMajorWinImg({ didWinMajorGrand }) {
  return (
    <VStack
      h="full"
      w="full"
      bgColor="blackAlpha.100"
      pos="absolute"
      top={0}
      justifyContent="center"
    >
      <VStack
        h={['60%', '350px']}
        w={['60%', '350px']}
        bgImage={`url(${
          didWinMajorGrand?.winGrand ? imgWinGrand : imgWinMajor
        })`}
        bgPosition="center"
        bgRepeat="no-repeat"
        bgSize="contain"
        justifyContent="flex-end"
      >
        <Text
          fontSize={['lg', '4xl']}
          pb={['5', '10%']}
          fontWeight="bold"
          color={didWinMajorGrand?.winMajor ? 'black' : 'white'}
        >
          {commaSeparator(didWinMajorGrand?.winning_amount)}
        </Text>
      </VStack>
    </VStack>
  );
}
