import { Box, Heading, Image, Text } from '@chakra-ui/react';
import { commaSeparator2 } from '../helpers/commaSeparator';
import GrandJackpotImg from '../assets/grandjackpot.png';
import MajorJackpotImg from '../assets/majorjackpot.png';
import AnimatedNumber from 'animated-number-react';
import { useState } from 'react';

export default function Price({ imgType = 'GRAND', amount = 0, winner = 0 }) {
  return (
    <Box>
      <Image
        src={imgType === 'GRAND' ? GrandJackpotImg : MajorJackpotImg}
        m="auto"
        alt="JACKPOT PRIZE"
        w={['80%', 'sm']}
      />

      <Heading
        fontSize="5xl"
        fontFamily="'Playfair Display', serif"
        color="#fff"
        bg="linear-gradient(to bottom, #fff0c3 22%,#ffe491 24%, #ffdf7b 26%, #ffde79 27%,#ffe285 40%,#ffffff 78%)"
        sx={{ WebkitTextFillColor: 'transparent' }}
        bgClip="text"
        textShadow={'md'}
        textAlign="center"
      >
        <Text as="span" fontSize="xl">
          PHP
        </Text>
        <AnimatedNumber
          value={amount}
          formatValue={value => commaSeparator2(value)}
        />
      </Heading>

      {winner !== false ? (
        <Heading as="h4" color="lightgreen" fontSize="xl">
          Winning Player/s:{' '}
          <Text as="span" color="gold" fontSize="3xl">
            {winner}
          </Text>
        </Heading>
      ) : null}
    </Box>
  );
}
