export const SITEID = 'gB3MZLdMV6mMm';
export const MERCHANTID = 't3skfnose3iojds09mMm';
export const ANNOUNCEMENT = 'announcement';
export const CURRENT_DECK_STATUS = 'deck status';
export const BETPLACED = 'new bet placed';
export const BETPLACEDGRAND = 'grandmajor bet placed';
export const DROPPED_BALL = 'droppedball';
export const DECLARATOR_UPDATE_EVENT = 'declarator_new_update';
export const PLAYERCOUNT = 'pcsodfjsdknaf';
export const DECLAVIEWER = 'updateviewer';
export const FILTEROPTIONS = ['Today', 'Last 3 Days', 'Last 7 Days'];
export const BETHISTORYTYPE = ['Regular', 'Major Jackpot', 'Grand Jackpot'];
