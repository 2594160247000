import { Navigate, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import Dashboard from "./Dashboard";

export default function RequireAuth({ allowedRoles = [] }) {
    const { auth } = useAuth()
    const location = useLocation()

    return allowedRoles.includes(auth?.roles) ?
        <Dashboard /> :
        auth?.user?.username ?
            <Navigate to="/unauthorized" state={{ from: location }} /> :
            <Navigate to="/login" state={{ from: location }} replace />
}