import {
  ChakraProvider,
  ColorModeScript,
  Spinner,
  extendTheme,
} from '@chakra-ui/react';
import React from 'react';
import * as ReactDOM from 'react-dom/client';
import {
  Navigate,
  Outlet,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';
import Console from './components/Console/Console';
import GrandJackpotBetting from './components/Console/GrandJackpotBetting';
import MegaJackpotBetting from './components/Console/MegaJackpotBetting';
import DeclaratorConsole from './components/DeclaratorConsole.js/DeclaratorConsole';
import ErrorPage from './components/ErrorPage';
import EventList from './components/Events/EventList';
import GrandMechanics from './components/Mechanics/GrandMechanics';
import MajorMechanics from './components/Mechanics/MajorMechanics';
import RegularMechanics from './components/Mechanics/RegularMechanics';
import PersistLogin from './components/PersistLogin';
import RedirectToUserDashboard from './components/Redirect';
import RequireAuth from './components/RequireAuth';
import { AuthProvider } from './context/AuthProvider';
import ROLES from './helpers/roles';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import PlayerDashboard from './pages/PlayerDashboard';
import Register from './pages/Register';
import BetReports from './pages/reports/Bet';
import EventHistory from './pages/reports/EventHistory';
import PointsReports from './pages/reports/Points';
import Unauthorized from './pages/Unauthorized';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);
const theme = extendTheme({
  config: { initialColorMode: 'dark', useSystemColorMode: false },
  colors: {
    brand: {
      50: '#f7d1362',
      100: '#f6cb1d',
      200: '#F6C604',
      300: '#ddb203',
      400: '#c49e03',
      500: '#ac8a02',
      600: '#937602',
      700: '#7b6302',
      800: '#624f01',
      900: '#493b01',
    },
  },
});

localStorage.setItem('chakra-ui-color-mode', 'dark');

const router = createBrowserRouter([
  {
    path: '/',
    element: <Outlet />,
    errorElement: <ErrorPage />,
    children: [
      { path: 'unauthorized', element: <Unauthorized /> },
      { path: 'register/:agentCode', element: <Register /> },
      {
        element: <PersistLogin />,
        children: [
          {
            index: true,
            element: <RedirectToUserDashboard />,
          },
          { path: 'login', element: <Login /> },
          {
            element: <RequireAuth allowedRoles={[ROLES.AGENT, ROLES.PLAYER]} />,
            children: [
              { path: 'home', element: <PlayerDashboard /> },
              { path: 'console/:eventId/:raceId?', element: <Console /> },
              {
                path: 'console/jackpot/:eventId',
                element: <GrandJackpotBetting />,
              },
              {
                path: 'console/megajackpot/:eventId',
                element: <MegaJackpotBetting />,
              },
            ],
          },
          {
            element: <RequireAuth allowedRoles={[ROLES.PLAYER]} />,
            children: [
              { path: 'player', element: <Navigate to={'/home'} replace /> },
            ],
          },
          {
            element: <RequireAuth allowedRoles={[ROLES.AGENT]} />,
            children: [
              { path: 'agent', element: <Navigate to={'/home'} replace /> },
            ],
          },
          {
            element: <RequireAuth allowedRoles={[ROLES.DECLARATOR]} />,
            children: [
              {
                path: 'declarator',
                element: <Outlet />,
                children: [
                  { index: true, element: <Navigate to={'/events'} replace /> },
                  {
                    path: 'console/:eventId/:raceNumber?',
                    element: <DeclaratorConsole />,
                  },
                ],
              },
            ],
          },
          {
            element: (
              <RequireAuth allowedRoles={[ROLES.PLAYER, ROLES.DECLARATOR]} />
            ),
            children: [{ path: 'events', element: <EventList /> }],
          },
          {
            element: <RequireAuth allowedRoles={[ROLES.PLAYER, ROLES.AGENT]} />,
            children: [
              { path: 'reports/bet', element: <BetReports /> },
              { path: 'reports/points', element: <PointsReports /> },
              { path: 'reports/events', element: <EventHistory /> },
              { path: 'mechanics/regular', element: <RegularMechanics /> },
              { path: 'mechanics/major', element: <MajorMechanics /> },
              { path: 'mechanics/grand', element: <GrandMechanics /> },
            ],
          },
        ],
      },
      { path: '*', element: <NotFound /> },
    ],
  },
]);

root.render(
  <React.StrictMode>
    <React.Fragment>
      <ColorModeScript initialColorMode="dark" />
      <ChakraProvider
        theme={theme}
        toastOptions={{
          defaultOptions: { position: 'top-right', isClosable: true },
        }}
      >
        <AuthProvider>
          <RouterProvider
            router={router}
            fallbackElement={
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
              />
            }
          />
        </AuthProvider>
      </ChakraProvider>
    </React.Fragment>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
