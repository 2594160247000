import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  CloseButton,
  Flex,
  FormControl,
  FormErrorMessage,
  Icon,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spacer,
  Stack,
} from '@chakra-ui/react';
import localforage from 'localforage';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaUser } from 'react-icons/fa';
import { RiLockPasswordFill } from 'react-icons/ri';
import { VscEye, VscEyeClosed } from 'react-icons/vsc';
import { useLocation, useNavigate } from 'react-router-dom';
import apiErrorHandler from '../api/apiErrorHandler';
import axios from '../api/axios';
import loginBgMobImg from '../assets/MOBILEBG.png';
import loginBgWebImg from '../assets/WEBBG.png';
import logoImg from '../assets/app_logo.png';
import useAuth from '../hooks/useAuth';
import PagcorLogo from '../components/PagcorLogo';
import accountType from '../helpers/accountType';

export default function Login() {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [errMsg, setErrMsg] = useState();
  const from = location.state?.from?.pathname || '/';

  const onSubmit = async data => {
    const { username, password } = data;
    setErrMsg(null);

    await axios
      .post('users/login', {
        username,
        password,
        ip_address: '0.0.0.0',
      })
      .then(async ({ data }) => {
        const {
          data: { refreshToken, token, points, ...user },
        } = data;

        const group = user?.group_type;
        const account = accountType(group);
        setAuth({
          user,
          refreshToken,
          token,
          roles: account.type,
          points,
          account,
        });
        await localforage.setItem('online_user', user);
        await localforage.setItem('online_refreshToken', refreshToken);
        await localforage.setItem('online_token', token);
        await localforage.setItem('online_roles', account.type);
        await localforage.setItem('online_points', points);
        await localforage.setItem('online_account', account);

        navigate(from, { replace: true, state: location.state?.from?.state });
      })
      .catch(err => {
        setErrMsg(apiErrorHandler(err));
      });
  };

  return (
    <Flex
      minH={'100vh'}
      align={{ base: 'flex-start', md: 'center' }}
      justify={'center'}
      bgRepeat={'no-repeat'}
      bgImage={[loginBgMobImg, loginBgWebImg]}
      bgSize="contain"
      bgPos={'center'}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack
          spacing={2}
          mx={'auto'}
          minW="sm"
          maxW="lg"
          pb={12}
          px={6}
          pt={{ base: '10', md: '0' }}
          mt={{ base: '36', md: 0 }}
        >
          <PagcorLogo />
          <Stack align={'center'}>
            <Image
              objectFit="cover"
              src={logoImg}
              alt="MARBLE"
              w={['2xs', 'sm']}
            />
          </Stack>

          <Stack spacing={4}>
            <FormControl isInvalid={errors.username}>
              <InputGroup>
                <InputLeftElement
                  h="full"
                  color="blue.600"
                  pointerEvents="none"
                  children={<Icon as={FaUser} />}
                />
                <Input
                  color="black"
                  backgroundColor="white"
                  size="md"
                  placeholder="Username"
                  {...register('username', {
                    required: 'Username is required.',
                  })}
                  _placeholder={{ opacity: 0.4, color: 'inherit' }}
                />
              </InputGroup>
              <FormErrorMessage>
                {errors.username && errors.username.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.password}>
              <InputGroup>
                <InputLeftElement
                  h="full"
                  color="blue.600"
                  pointerEvents="none"
                  children={<Icon as={RiLockPasswordFill} />}
                />
                <Input
                  color="black"
                  backgroundColor="white"
                  size="md"
                  placeholder="Password"
                  type={showPassword ? 'text' : 'password'}
                  {...register('password', {
                    required: 'Password is required.',
                  })}
                  _placeholder={{ opacity: 0.4, color: 'inherit' }}
                />
                <InputRightElement h="full">
                  <Button
                    // variant={'ghost'}
                    // color="blue.600"
                    bg={'transparent'}
                    onClick={() =>
                      setShowPassword(showPassword => !showPassword)
                    }
                  >
                    {showPassword ? (
                      <Icon as={VscEye} />
                    ) : (
                      <Icon as={VscEyeClosed} />
                    )}
                  </Button>
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage>
                {errors.password && errors.password.message}
              </FormErrorMessage>
            </FormControl>

            {errMsg && (
              <Alert status="error" variant="left-accent">
                <AlertIcon />
                <Box flex={'1'}>
                  <AlertTitle>Login Failed</AlertTitle>
                  <AlertDescription display="block">{errMsg}</AlertDescription>
                </Box>
                <CloseButton
                  position="absolute"
                  right="8px"
                  top="8px"
                  onClick={() => setErrMsg(null)}
                />
              </Alert>
            )}

            <Button
              mt="6"
              colorScheme="brand"
              w="full"
              isLoading={isSubmitting}
              loadingText="Logging in..."
              type={'submit'}
              size="lg"
              color="white"
            >
              L O G I N
            </Button>
            <Spacer />
            <Box as="p" fontSize="xs" color="gray" textAlign="center">
              v0.1.0
            </Box>
          </Stack>
        </Stack>
      </form>
    </Flex>
  );
}
