/* eslint-disable eqeqeq */
import {
  Box,
  Button,
  Center,
  Circle,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useEffect, useReducer, useState } from 'react';
import apiErrorHandler from '../../api/apiErrorHandler';
import BetButtonColor from '../../helpers/betButtonColor';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { CURRENT_DECK_STATUS } from '../../helpers/constants';

export default function ExplodeEventButtons({
  eventButtons = [],
  currentRace,
  eventRaceHistory = [],
  setRefreshHistory,
  socketDeclarator,
}) {
  const axiosPrivate = useAxiosPrivate();
  const [buttons, setButtons] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [bola, setBola] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [currentAllowed, setCurrentAllowed] = useState({
    RED: 1,
    BLUE: 1,
    GREEN: 1,
    YELLOW: 1,
    PINK: 1,
    WHITE: 1,
  });

  const handleSelect = bols => {
    if (currentRace?.status !== 'CLOSE' || bols?.shouldDisable) {
      return;
    }

    setBola(bols);
    onOpen();
  };

  const handleDroppedBola = async () => {
    setIsLoading(true);
    toast.closeAll();

    const colors =
      eventRaceHistory.find(
        item => item?.raceDetails?.f_no === currentRace?.fightNumber
      )?.color || [];

    const alreadySelected = colors?.findIndex(
      e => e?.color == bola?.button_color && e?.label == bola?.label
    );

    if (alreadySelected !== -1) {
      toast({
        title: 'Failed',
        description: `${bola.button_color} ${bola.label} already selected.`,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      setIsLoading(false);
      return;
    }

    await axiosPrivate
      .post('declarator/race/pick_color', {
        raceId: currentRace?.id,
        declare_color: bola?.button_color,
        declare_label: bola?.label,
      })
      .then(({ data }) => {
        toast({
          title: 'Success',
          description: '',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        socketDeclarator.emit(CURRENT_DECK_STATUS, {
          eventId: currentRace?.eventId,
          raceId: currentRace?.id,
        });
      })
      .catch(err => {
        const msg = apiErrorHandler(err);
        console.log(msg);
        toast({
          title: 'Failed',
          description: msg,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      })
      .finally(() => {
        onClose();
        setIsLoading(false);
        setRefreshHistory(prev => !prev);
      });
  };

  useEffect(() => {
    try {
      const currentRaceHistory = eventRaceHistory.find(
        item => item?.raceDetails?.raceId === currentRace?.id
      );
      // const isDisabe = false;

      const isDisabe = (label, declaredColors) => {
        // is 1 declared?
        // is 2 declared?
        // is 3 declared?

        const alreadyDeclared = declaredColors.findIndex(
          item => Number(item?.label) === label
        );

        if (alreadyDeclared === -1) {
          return false;
        }

        return true;
      };

      const bolas = [];
      eventButtons.forEach(button => {
        const labels = JSON.parse(button?.button_label);

        labels.forEach(label => {
          const declaredColors = [];

          currentRaceHistory?.color?.forEach(item => {
            if (item?.color === button?.button_name) {
              declaredColors.push(item);
            }
          });

          // const shouldDisable = isDisabe(Number(label), declaredColors);

          bolas.push({ label, ...button, shouldDisable: false });
        });
      });

      setButtons(bolas);
    } catch (error) {
      setButtons([]);
    }
  }, [eventButtons, currentRace?.id, eventRaceHistory]);

  return (
    <>
      <Box borderRadius="md" border="1px" p={2} borderColor="gray.100" mb="5">
        <Text fontWeight={'bold'} mb={2}>
          SELECT DROPPED COLOR
        </Text>
        <Flex
          spacing="4"
          mb="5"
          dir="row"
          flexWrap="wrap"
          gap="5"
          justifyContent="center"
          filter={
            currentRace?.status === 'CLOSE'
              ? 'grayscale(0%)'
              : 'grayscale(100%)'
          }
        >
          {buttons.map(bola =>
            bola?.isJackpot === 1 ? null : (
              <Box
                filter={
                  bola?.shouldDisable ? 'grayscale(90%)' : 'grayscale(0%)'
                }
                key={`${bola?.id}-${bola?.label}`}
              >
                <Circle
                  bgImage={`/150x150pxballs/BALLS-${bola?.button_color}.png`}
                  bgSize="contain"
                  w="28px"
                  h="28px"
                  onClick={handleSelect.bind(this, bola)}
                  cursor={
                    currentRace?.status === 'CLOSE' &&
                    bola?.shouldDisable === false
                      ? 'pointer'
                      : 'not-allowed'
                  }
                ></Circle>
              </Box>
            )
          )}
        </Flex>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>You selected:</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Center>
              <Circle
                h="50px"
                w="50px"
                bgImage={`/150x150pxballs/BALLS-${bola?.button_color}.png`}
                bgSize="contain"
              ></Circle>
            </Center>
          </ModalBody>

          <ModalFooter>
            <Button
              isDisabled={isLoading}
              isLoading={isLoading}
              colorScheme="green"
              onClick={handleDroppedBola}
              mr={4}
              minW={100}
            >
              {isLoading ? 'Please wait' : 'Confirm'}
            </Button>
            <Button colorScheme="blue" mr={3} onClick={onClose} minW={100}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
