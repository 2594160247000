import {
  Box,
  Center,
  Circle,
  Heading,
  Image,
  List,
  ListIcon,
  ListItem,
  OrderedList,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tr,
  UnorderedList,
} from '@chakra-ui/react';
import logo from '../../assets/app_logo.png';
import { FaRegCircle } from 'react-icons/fa';

export default function GrandMechanics() {
  return (
    <Box p={4} w={['full', '2xl']} m="auto">
      <Center textAlign="center" mb={4}>
        <Image src={logo} w={['150px', '200px']} alt="MARBLE Color Game" />
      </Center>
      <Heading
        as="h1"
        size={['md', 'lg']}
        textAlign="center"
        sx={{ WebkitTextStroke: '0.5px red' }}
        textShadow="1px 1px 1px rgba(255,165,0,0.64)"
      >
        GRAND JACKPOT
      </Heading>

      <Heading
        as="h2"
        mt="5"
        size="md"
        // sx={{ WebkitTextStroke: '0.5px white' }}
        color="yellow"
        // textShadow="1px 1px 1px rgb(157 195 230)"
      >
        OVERVIEW
      </Heading>
      <Box padding={4}>
        <UnorderedList styleType="'-'">
          <ListItem>
            GRAND JACKPOT betting is only available every start of the first
            race per event.
          </ListItem>
          <ListItem>
            There are eight (8) race selections per grand jackpot betting.
          </ListItem>
          <ListItem>
            Winner/s will be announced or declared every 8th race.
          </ListItem>
          <ListItem>
            The GRAND JACKPOT PRIZE will be distributed equally based on the
            number of winners.
          </ListItem>
          <ListItem>
            In the event that there are no winners in the current or previous
            event, the GRAND JACKPOT PRIZE will be automatically carry over in
            the next event.
          </ListItem>
        </UnorderedList>
      </Box>

      <Heading
        as="h2"
        mt="5"
        size="md"
        // sx={{ WebkitTextStroke: '0.5px white' }}
        color="yellow"
        // textShadow="1px 1px 1px rgb(157 195 230)"
      >
        GAME MECHANICS
      </Heading>
      <Box padding={4}>
        <OrderedList>
          <ListItem>
            At the start of every new event, a betting countdown commences.
            Select the color per race you wish to bet on and place your bet
            successfully.
          </ListItem>
          <ListItem>
            Player will choose a possible color to win per race in the grand
            jackpot betting console. See below the assigned color in the
            Tambiolo Machine:
            <List>
              <ListItem>
                <ListIcon as={FaRegCircle} w="2" color="red" />
                <Text as="i" fontWeight="medium" color="red">
                  RED
                </Text>
              </ListItem>
              <ListItem>
                <ListIcon as={FaRegCircle} w="2" color="blue" />
                <Text as="i" fontWeight="medium" color="blue">
                  BLUE
                </Text>
              </ListItem>
              <ListItem>
                <ListIcon as={FaRegCircle} w="2" color="green" />
                <Text as="i" fontWeight="medium" color="green">
                  GREEN
                </Text>
              </ListItem>
              <ListItem>
                <ListIcon as={FaRegCircle} w="2" color="yellow" />
                <Text as="i" fontWeight="medium" color="yellow">
                  YELLOW
                </Text>
              </ListItem>
              <ListItem>
                <ListIcon as={FaRegCircle} w="2" color="rgb(255 153 254)" />
                <Text as="i" fontWeight="medium" color="rgb(255 153 254)">
                  PINK
                </Text>
                <ListItem>
                  <ListIcon as={FaRegCircle} w="2" color="white" />
                  <Text as="i" fontWeight="medium" color="white">
                    WHITE
                  </Text>
                </ListItem>
              </ListItem>
            </List>
          </ListItem>
          <ListItem>
            No further bets will be accepted once the countdown ends in 30
            seconds.
          </ListItem>
          <ListItem>
            Player will win GRAND JACKPOT PRIZE if all selected possible colors
            per race are come out.
            <List>
              <ListItem>
                <ListIcon as={FaRegCircle} w="2" />
                <Text as="span">Example:</Text>
                <TableContainer>
                  <Table
                    borderColor="white"
                    sx={{ borderCollapse: 'collapse' }}
                  >
                    <Tbody>
                      <Tr>
                        <Td
                          border="1px solid white"
                          colSpan={8}
                          p={0}
                          fontWeight="medium"
                        >
                          MY BET & RESULT PER PAGE
                        </Td>
                      </Tr>
                      <Tr>
                        {[1, 2, 3, 4, 5, 6, 7, 8].map(item => (
                          <Td
                            border="1px solid white"
                            p={2}
                            fontWeight="medium"
                          >
                            RACE {item}
                          </Td>
                        ))}
                      </Tr>
                      <Tr>
                        {[
                          'RED',
                          'BLUE',
                          'PINK',
                          'GREEN',
                          'YELLOW',
                          'WHITE',
                          'BLUE',
                          'RED',
                        ].map(item => (
                          <Td border="1px solid white" p={2}>
                            <Center>
                              <Circle
                                h="25px"
                                w="25px"
                                bg={`url(/150x150pxballs/BALLS-${item}.png)`}
                                overflow="hidden"
                                bgSize="contain"
                              ></Circle>
                            </Center>
                          </Td>
                        ))}
                      </Tr>
                    </Tbody>
                  </Table>
                </TableContainer>
              </ListItem>
            </List>
          </ListItem>
        </OrderedList>
      </Box>

      <Heading
        as="h2"
        mt="5"
        size="md"
        // sx={{ WebkitTextStroke: '0.5px white' }}
        color="yellow"
        // textShadow="1px 1px 1px rgb(157 195 230)"
      >
        PAYOUT/ODDS
      </Heading>
      <Box padding={4}>
        <OrderedList>
          <ListItem>
            The system will automatically deduct the assigned percentage from
            the total winnings as company’s commission.
          </ListItem>
          <ListItem>
            There is an assigned GRAND JACKPOT bet which will be set by the
            company/operator before the start of every event.
          </ListItem>
          <ListItem>
            The company/operator will have an option to set initial GRAND
            JACKPOT PRIZE per event.
          </ListItem>
        </OrderedList>
      </Box>

      <Heading
        as="h3"
        size="xs"
        // sx={{ WebkitTextStroke: '0.5px white' }}
        color="white"
        // textShadow="1px 1px 1px rgb(157 195 230)"
      >
        RETURN TO PLAYER: 100% Plus
      </Heading>
    </Box>
  );
}
