import { CloseIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Circle,
  Drawer,
  DrawerBody,
  DrawerContent,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import ChipImage from '../../assets/chips150x150.png';
import amountButtons from '../../helpers/amountButtons';
import { BETPLACED } from '../../helpers/constants';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

export default function ConfirmBetDialog({
  button_name: buttonName,
  button_label: buttonLabel,
  maxBet,
  minBet,
  onClose,
  isOpen,
  race: raceInfo,
  eventId,
  setRefreshRaceBet,
  socketPlayer,
  getPoints,
}) {
  const toast = useToast();
  const {
    handleSubmit,
    watch,
    register,
    reset,
    trigger,
    setValue,
    setFocus,
    formState: { errors, isSubmitting, isValid },
  } = useForm();
  const betAmount = watch('amount', '');
  const axiosPrivate = useAxiosPrivate();

  const cleanLabel = label => String(label).replace(/_/i, ' ');

  async function onSubmit(values) {
    toast.closeAll();
    if (isSubmitting) {
      return;
    }
    const url = 'bet';
    const data = {
      amount: values.amount,
      eventId,
      raceId: raceInfo?.id,
      bet_color: buttonName,
    };

    await axiosPrivate
      .post(url, data)
      .then(({ data }) => {
        handleOnClose();

        toast({
          id: `${raceInfo?.id}-${values.amount}`,
          title: data?.msg,
          description: '',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        setRefreshRaceBet(prev => !prev);
        socketPlayer.emit(BETPLACED);
      })
      .catch(err => {
        console.error(err);
        toast({
          id: `${raceInfo?.id}-${values.amount}`,
          title: err?.response?.data?.msg,
          description: '',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      })
      .finally(() => getPoints());

    // return new Promise(resolve => {
    //   setTimeout(() => {
    //     alert(JSON.stringify(values, null, 2));
    //     resolve();
    //   }, 3000);
    // });
  }

  const handleOnClose = () => {
    reset();
    onClose();
  };

  useEffect(() => {
    setFocus('amount');
  }, [setFocus]);

  return (
    <Drawer
      onClose={handleOnClose}
      isOpen={isOpen}
      placement="bottom"
      size="sm"
    >
      {/* <DrawerOverlay /> */}
      <DrawerContent>
        {/* <DrawerCloseButton colorScheme="red" /> */}
        {/* <DrawerHeader>{`drawer contents`}</DrawerHeader> */}
        <DrawerBody bg="blackAlpha.900">
          <Box py={2}>
            <Text fontWeight="semibold" color="white" mb="4">
              BET ON {cleanLabel(buttonName)}
            </Text>
            <Flex
              gap={'2'}
              overflowX={['scroll', 'hidden']}
              overflowY="hidden"
              mb={4}
            >
              {amountButtons.map(item => (
                <Circle
                  bgImg={ChipImage}
                  bgRepeat="no-repeat"
                  bgSize="contain"
                  size="35px"
                  color="black"
                  bgColor="orange"
                  key={item.label}
                  cursor="pointer"
                  fontWeight="bold"
                  onClick={() =>
                    setValue('amount', Number(betAmount) + Number(item.value), {
                      shouldValidate: true,
                    })
                  }
                >
                  {item.label}
                </Circle>
                // <Button
                //   colorScheme="orange"

                //   size={'sm'}
                //   w="200px"

                // >
                //   {item.label}
                // </Button>
              ))}
            </Flex>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl isInvalid={errors.amount}>
                <InputGroup>
                  <Input
                    type="number"
                    id="amount"
                    color="white"
                    placeholder="Enter amount"
                    fontWeight="bold"
                    _placeholder={{ color: 'inherit' }}
                    size="lg"
                    {...register('amount', {
                      onChange: () => trigger(),
                      required: 'Amount is required.',
                      min: {
                        value: minBet,
                        message: `Minimum bet is ${minBet}`,
                      },
                      max: { value: maxBet, message: `Max bet is ${maxBet}` },
                    })}
                  />
                  {betAmount ? (
                    <InputRightElement mt={1}>
                      <CloseIcon
                        color="red.500"
                        onClick={() =>
                          setValue('amount', '', { shouldValidate: true })
                        }
                      />
                    </InputRightElement>
                  ) : null}
                </InputGroup>
                <Box h="24px">
                  <FormErrorMessage color="red.300">
                    {errors.amount && errors.amount.message}
                  </FormErrorMessage>
                </Box>
              </FormControl>
              <Box textAlign={['center', 'end']} mt={2}>
                <Button
                  px={3}
                  mb={[3, 0]}
                  mr={[0, 4]}
                  w={['full', 'unset']}
                  type="submit"
                  colorScheme="brand"
                  isDisabled={isSubmitting || !isValid}
                  isLoading={isSubmitting}
                  size="lg"
                >
                  PLACE BET ON {cleanLabel(buttonName)}
                </Button>
                <Button
                  w={['96%', 'unset']}
                  colorScheme="red"
                  onClick={handleOnClose}
                  type="button"
                >
                  CANCEL
                </Button>
              </Box>
            </form>
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
