import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  HStack,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SkeletonText,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useMediaQuery,
  useRadioGroup,
} from '@chakra-ui/react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import GenerateCircle from '../../components/GenerateCircle';
import RadioCard from '../../components/RadioCard';
import BetButtonColor from '../../helpers/betButtonColor';
import commaSeparator from '../../helpers/commaSeparator';
import { FILTEROPTIONS as options } from '../../helpers/constants';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

export default function PointsReports() {
  const axiosPrivate = useAxiosPrivate();
  const [isLoading, setIsLoading] = useState(true);
  const [selected, setSelected] = useState();
  const [list, setList] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLargerThan800] = useMediaQuery('(min-width: 800px)');

  const [filter, setFilter] = useState(options[0]);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState({
    page_prev: null,
    next_page: null,
  });

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'recordLimit',
    defaultValue: 'Today',
    onChange: setFilter,
  });

  const group = getRootProps();

  const handleShowDetails = data => {
    setSelected(data);
    onOpen();
  };

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    async function getList() {
      let _filter = 1;

      switch (filter) {
        case 'Today':
          _filter = 1;
          break;
        case 'Last 3 Days':
          _filter = 2;
          break;
        case 'Last 7 Days':
          _filter = 3;
          break;
        default:
          _filter = 1;
          break;
      }

      const params = new URLSearchParams({ page, filter: _filter });

      await axiosPrivate
        .get('points/history', { params, signal: controller.signal })
        .then(({ data }) => {
          const { msg, ...pagination } = data;

          if (Array.isArray(msg)) {
            isMounted && setList(msg);
          } else {
            isMounted && setList([]);
          }

          isMounted &&
            setPagination({
              page_prev: pagination?.page_prev,
              next_page: pagination?.next_page,
            });
        })
        .catch(err => {
          console.error(err.response?.data?.msg || err);
          setList([]);
          isMounted &&
            setPagination({
              page_prev: null,
              next_page: null,
            });
        })
        .finally(() => setIsLoading(false));
    }

    getList();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [axiosPrivate, filter, page]);

  return (
    <>
      <Box p={4}>
        <Heading as="h1" fontSize="lg">
          Points History
        </Heading>

        {isLoading ? (
          <SkeletonText mt="4" noOfLines={4} spacing="4" skeletonHeight="2" />
        ) : null}

        <HStack {...group} my={2} justifyContent={['flex-start', 'flex-end']}>
          {options.map(value => {
            const radio = getRadioProps({ value });
            return (
              <RadioCard key={value} {...radio}>
                {value}
              </RadioCard>
            );
          })}
        </HStack>

        {isLargerThan800 ? (
          <Wide list={list} />
        ) : (
          <Card list={list} handleShowDetails={handleShowDetails} />
        )}
        {list.length <= 0 ? (
          <Box mt={4} textAlign="center">
            No record.
          </Box>
        ) : null}
        <Box mt={4} textAlign="end">
          <Button
            leftIcon={<ChevronLeftIcon />}
            colorScheme="teal"
            variant="outline"
            size="sm"
            mr={2}
            isDisabled={!pagination?.page_prev}
            onClick={() => setPage(pagination?.page_prev)}
          >
            Prev
          </Button>
          <Button
            rightIcon={<ChevronRightIcon />}
            colorScheme="teal"
            variant="outline"
            size="sm"
            isDisabled={!pagination?.next_page}
            onClick={() => setPage(pagination?.next_page)}
          >
            Next
          </Button>
        </Box>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} size="sm">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader pb="1">Transaction Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              <Text as="span" fontWeight="bold" fontSize="sm">
                Date/Time:
              </Text>{' '}
              {moment(selected?.transaction_date).format('MMM D, YYYY, h:mm a')}
            </Text>

            <Text>
              <Text as="span" fontWeight="bold" fontSize="sm">
                Transaction Type:
              </Text>{' '}
              {selected?.trans_type === 'BET/ROLLBACK'
                ? 'ROLLBACK'
                : selected?.trans_type}
            </Text>

            <Box>
              <Text as="span" fontWeight="bold" fontSize="sm">
                Bet On:
              </Text>{' '}
              {selected?.trans_type === 'BET_GRAND_JACKPOT' ||
              selected?.trans_type === 'BET_MEGA_JACKPOT' ? (
                <HStack>
                  <GenerateCircle data={selected?.bet_type} />
                </HStack>
              ) : (
                <Text
                  as="span"
                  color={
                    BetButtonColor?.[selected?.bet_type]?.textColor?.report
                  }
                >
                  {selected?.bet_type}
                </Text>
              )}
            </Box>

            <Text>
              <Text as="span" fontWeight="bold" fontSize="sm">
                Amount:
              </Text>{' '}
              {commaSeparator(selected?.amount)}
            </Text>
            <Text>
              <Text as="span" fontWeight="bold" fontSize="sm">
                Balance Before:
              </Text>{' '}
              {commaSeparator(selected?.balance_before)}
            </Text>
            <Text>
              <Text as="span" fontWeight="bold" fontSize="sm">
                Balance After:
              </Text>{' '}
              {commaSeparator(selected?.balance_after)}
            </Text>
            {/* <Text>
              <Text as="span" fontWeight="bold" fontSize="sm">
                Status:
              </Text>{' '}
              {selected?.status || selected?.STATUS}
            </Text> */}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

const transactionColor = transType => {
  if (transType === 'ROLLBACK' || transType === 'BET/ROLLBACK') return 'red';
  return 'initial';
};

function TransactionType({ transType }) {
  return (
    <Text fontWeight="bold" color={transactionColor(transType)}>
      {transType === 'BET/ROLLBACK' ? 'ROLLBACK' : transType}
    </Text>
  );
}

function Wide({ list }) {
  return (
    <Table>
      <Thead>
        <Tr>
          <Th>Date/Time</Th>
          <Th>Transaction Type</Th>
          <Th>Bet On</Th>
          <Th>Amount</Th>
          <Th>Balance Before</Th>
          <Th>Balance After</Th>
          {/* <Th>Status</Th> */}
        </Tr>
      </Thead>
      <Tbody>
        {list.map(item => (
          <Tr key={item?.transaction_date}>
            <Td>
              {moment(item?.transaction_date).format('MMM D, YYYY, h:mm a')}
            </Td>
            <Td>
              <TransactionType
                transType={
                  item?.trans_type === 'BET_MEGA_JACKPOT'
                    ? 'BET_MAJOR_JACKPOT'
                    : item?.trans_type
                }
              />
            </Td>
            <Td>
              {item?.trans_type === 'BET_GRAND_JACKPOT' ||
              item?.trans_type === 'BET_MEGA_JACKPOT' ? (
                <HStack>
                  <GenerateCircle data={item?.bet_type} />
                </HStack>
              ) : (
                <Text
                  color={BetButtonColor?.[item?.bet_type]?.textColor?.report}
                >
                  {item?.bet_type}
                </Text>
              )}
            </Td>
            <Td
              color={
                item?.trans_type === 'CASHOUT'
                  ? 'red.500'
                  : item?.trans_type === 'CASHIN' ||
                    item?.trans_type === 'BET WIN'
                  ? 'green.500'
                  : 'initial'
              }
            >
              {commaSeparator(item?.amount)}
            </Td>
            <Td>{commaSeparator(item?.balance_before)}</Td>
            <Td>{commaSeparator(item?.balance_after)}</Td>
            {/* <Td>{item?.status || item?.STATUS}</Td> */}
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}

function Card({ list = [], handleShowDetails }) {
  return list.map(item => (
    <Box
      key={item?.id}
      borderBottom="1px"
      py={2}
      borderBottomColor="gray.200"
      onClick={handleShowDetails.bind(this, item)}
    >
      <HStack>
        <Box flex={1}>
          <TransactionType transType={item?.trans_type} />
          <Text fontSize="xs" color="gray">
            {moment(item?.transaction_date).format('MMM D, YYYY, h:mm a')}
          </Text>
        </Box>
        <Box>
          <Text
            color={
              item?.trans_type === 'CASHOUT'
                ? 'red.500'
                : item?.trans_type === 'CASHIN' ||
                  item?.trans_type === 'BET WIN'
                ? 'green.500'
                : 'initial'
            }
            fontWeight="bold"
          >
            {item?.trans_type === 'CASHOUT'
              ? '-'
              : item?.trans_type === 'CASHIN' || item?.trans_type === 'BET WIN'
              ? '+'
              : null}
            {item?.amount ? commaSeparator(item?.amount) : null}
          </Text>
        </Box>
      </HStack>
    </Box>
  ));
}
