import {
  ChevronDownIcon,
  ChevronRightIcon,
  CloseIcon,
  HamburgerIcon,
  Icon,
} from '@chakra-ui/icons';
import {
  Avatar,
  Box,
  Collapse,
  Flex,
  HStack,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  VStack,
  useClipboard,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { CgLogOut } from 'react-icons/cg';
import { HiClipboardCopy } from 'react-icons/hi';
import { ImProfile } from 'react-icons/im';
import {
  NavLink,
  Link as RouterLink,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import commaSeparator from '../helpers/commaSeparator';
import ROLES from '../helpers/roles';
import useAuth from '../hooks/useAuth';
import useLogout from '../hooks/useLogout';
import Logo from './Logo';

export default function Navigation() {
  const { auth } = useAuth();
  const { isOpen, onToggle } = useDisclosure();
  const navigate = useNavigate();
  const logout = useLogout();
  const toast = useToast();
  const { onCopy, hasCopied } = useClipboard(
    `${window.location.origin}/register/${auth.user?.referralCode || 1234}`
  );
  let location = useLocation();

  const fullName =
    `${auth?.user?.first_name} ${auth?.user?.last_name}` || 'Welcome';

  const signOut = async () => {
    await logout();
    navigate('/login');
  };

  const handleLogoClick = () => {
    if (auth.roles === ROLES.DECLARATOR) navigate('/events');
    if (auth.roles === ROLES.PLAYER) navigate('/player');
  };

  useEffect(() => {
    toast.closeAll();
  }, [location, toast]);

  useEffect(() => {
    hasCopied &&
      toast({
        title: 'Referral Link.',
        description: 'Referral link copied.',
        status: 'info',
        duration: 9000,
        isClosable: true,
      });
  }, [hasCopied, toast]);

  return (
    <Box pos={'sticky'} top={'0'} zIndex={'sticky'}>
      <Flex
        bg={useColorModeValue('white', 'gray.800')}
        color={useColorModeValue('gray.600', 'white')}
        minH={'60px'}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.200', 'gray.900')}
        align={'center'}
      >
        <Flex
          flex={{ base: 1, md: 'auto' }}
          ml={{ base: -2 }}
          display={{ base: 'flex', md: 'none' }}
        >
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant={'ghost'}
            aria-label={'Toogle Navigation'}
          />
        </Flex>
        <Flex
          flex={{ base: 1 }}
          justify={{ base: 'center', md: 'start' }}
          alignItems={'center'}
        >
          <HStack cursor={'pointer'} onClick={handleLogoClick} mr="4">
            <Logo size="40px" />
            {/* <Box mx={2} display={{ base: 'none', md: 'block' }}>
              <LogoText sw="110px" />
            </Box> */}
          </HStack>

          <Flex display={{ base: 'none', md: 'flex' }}>
            <DesktopNav />
          </Flex>
        </Flex>

        <Stack
          flex={{ base: 1, md: 'inherit' }}
          justify={'flex-end'}
          direction={'row'}
          spacing={6}
          align={'center'}
        >
          {/* <IconButton
            size="lg"
            variant="ghost"
            aria-label="open menu"
            icon={<FiBell />}
          /> */}

          <Menu>
            <MenuButton>
              <HStack>
                <Avatar bg={'goldenrod'} color={'white'} size={'sm'} />
                <VStack
                  display={{ base: 'none', md: 'inline-flex' }}
                  alignItems={'flex-start'}
                  spacing={'1px'}
                  ml={2}
                >
                  <Text fontSize={'sm'}>{fullName}</Text>
                  <Text fontSize={'xs'} color={'gray.600'}>
                    {auth?.user?.username}
                  </Text>
                </VStack>
                <Box display={{ base: 'none', md: 'flex' }}>
                  <ChevronDownIcon />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue('white', 'gray.900')}
              borderColor={useColorModeValue('gray.200', 'gray.700')}
            >
              {auth?.account?.showPoints ? (
                <>
                  <MenuItem
                    closeOnSelect={false}
                    backgroundColor={'transparent'}
                  >
                    <VStack alignItems={'start'}>
                      <Box>
                        <Text
                          as={'span'}
                          fontWeight={'500'}
                          color={'blue.500'}
                          fontSize={'sm'}
                        >
                          Points:
                        </Text>{' '}
                        {commaSeparator(auth?.points || 0)}
                      </Box>
                    </VStack>
                  </MenuItem>
                </>
              ) : null}

              {auth?.account?.showReferralLink ? (
                <>
                  <MenuDivider />
                  <MenuItem
                    backgroundColor={'transparent'}
                    icon={<HiClipboardCopy />}
                    onClick={onCopy}
                  >
                    Copy Referal Link
                  </MenuItem>
                </>
              ) : null}

              {auth?.account?.showPoints ? <MenuDivider /> : null}
              <MenuItem backgroundColor={'transparent'} icon={<ImProfile />}>
                Profile
              </MenuItem>
              {/* <MenuItem icon={<UnlockIcon />}>Change Password</MenuItem> */}
              <MenuDivider />
              <MenuItem
                backgroundColor={'transparent'}
                onClick={signOut}
                icon={<CgLogOut />}
              >
                Log out
              </MenuItem>
            </MenuList>
          </Menu>
        </Stack>
      </Flex>
      <Collapse in={isOpen} animateOpacity>
        <MobileNav closeMobileNav={onToggle} />
      </Collapse>
    </Box>
  );
}

const DesktopNav = () => {
  const linkColor = useColorModeValue('gray.600', 'gray.200');
  // const linkHoverColor = useColorModeValue('blue.600', 'white');
  const popoverContentBgColor = useColorModeValue('white', 'gray.800');

  return (
    <Stack direction={'row'} spacing={4}>
      {NAV().map(navItem => (
        <Box key={navItem.label}>
          <Popover trigger={'hover'} placement={'bottom-start'}>
            <PopoverTrigger>
              <Link
                p={2}
                as={NavLink}
                fontSize={'md'}
                fontWeight={'bold'}
                color={linkColor}
                _hover={{ textDecoration: 'none', color: 'goldenrod' }}
                to={navItem?.href || '#'}
              >
                {navItem.label}
              </Link>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={'xl'}
                bg={popoverContentBgColor}
                p={4}
                rounded={'xl'}
                minW={'sm'}
              >
                <Stack>
                  {navItem.children.map(child => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const DesktopSubNav = ({ label, href, subLabel, NavItem }) => {
  return (
    <Link
      as={RouterLink}
      to={href}
      role={'group'}
      display={'block'}
      p={2}
      rounded={'md'}
      _hover={{ bg: useColorModeValue('goldenrod', 'gray.900') }}
      variant="ghost"
    >
      <Stack direction={'row'} align={'center'}>
        <Box>
          <Text
            transition={'all .3s ease'}
            _groupHover={{ color: 'goldenrod' }}
            fontWeight={500}
          >
            {label}
          </Text>
          <Text>{subLabel}</Text>
        </Box>
        <Flex
          transition={'all .3s ease'}
          transform={'translateX(-10px)'}
          opacity={0}
          _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
          justify={'flex-end'}
          align={'center'}
          flex={1}
        >
          <Icon color={'goldenrod'} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Link>
  );
};

const MobileNav = ({ closeMobileNav }) => {
  return (
    <Stack
      bg={useColorModeValue('white', 'gray.800')}
      p={4}
      display={{ md: 'none' }}
      pos={'absolute'}
      w={'full'}
    >
      {NAV().map(navItem => (
        <MobileNavItem
          key={navItem.label}
          closeMobileNav={closeMobileNav}
          {...navItem}
        />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href, closeMobileNav }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle} zIndex={'overlay'}>
      <Flex
        py={2}
        as={RouterLink}
        to={href ?? '#'}
        justify={'space-between'}
        align={'center'}
        _hover={{ textDecoration: 'none' }}
        onClick={!children ? closeMobileNav : () => {}}
      >
        <Text
          fontWeight={600}
          color={useColorModeValue('gray.600', 'gray.200')}
        >
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={'all .25s ease-in-out'}
            transform={isOpen ? 'rotate(180deg)' : ''}
            w={6}
            h={6}
          />
        )}
      </Flex>
      <Collapse in={isOpen} animateOpacity>
        <Stack
          mt={0}
          pl={4}
          borderLeft={1}
          borderStyle={'solid'}
          borderColor={useColorModeValue('gray.200', 'gray.700')}
          align={'start'}
        >
          {(children || []).map(child => (
            <Link
              key={child.label}
              py={2}
              as={RouterLink}
              to={child.href}
              onClick={closeMobileNav}
            >
              {child.label}
            </Link>
          ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

const agentMenus = [
  {
    label: 'Home',
    href: '/home',
  },
  {
    label: 'Reports',
    children: [
      {
        label: 'Points History',
        href: 'reports/points',
      },
      {
        label: 'Bet History',
        href: 'reports/bet',
      },
      {
        label: 'Event History',
        href: 'reports/events',
      },
    ],
  },
  {
    label: 'Marketing Portal',
    children: [
      {
        label: 'Players',
        href: 'mechanics/regular',
      },
      {
        label: 'Downlines',
        href: 'mechanics/major',
      },
      {
        label: 'Commissions',
        href: 'mechanics/grand',
      },
    ],
  },
  {
    label: 'Game Mechanics',
    children: [
      {
        label: 'Regular Race',
        href: 'mechanics/regular',
      },
      {
        label: 'Major Jackpot',
        href: 'mechanics/major',
      },
      {
        label: 'Grand Jackpot',
        href: 'mechanics/grand',
      },
    ],
  },
];

const declaratorMenus = [
  {
    label: 'Events',
    href: '/events',
  },
];

const defaultMenus = [
  {
    label: 'Games',
    href: '/games/8',
  },
];

const playerMenus = [
  {
    label: 'Home',
    href: '/home',
  },
  {
    label: 'Reports',
    children: [
      {
        label: 'Points History',
        href: 'reports/points',
      },
      {
        label: 'Bet History',
        href: 'reports/bet',
      },
      {
        label: 'Event History',
        href: 'reports/events',
      },
    ],
  },
  {
    label: 'Game Mechanics',
    children: [
      {
        label: 'Regular Race',
        href: 'mechanics/regular',
      },
      {
        label: 'Major Jackpot',
        href: 'mechanics/major',
      },
      {
        label: 'Grand Jackpot',
        href: 'mechanics/grand',
      },
    ],
  },
];

const NAV = () => {
  const { auth } = useAuth();
  if (auth?.roles === ROLES.DECLARATOR) return declaratorMenus;
  if (auth?.roles === ROLES.PLAYER) return playerMenus;
  if (auth?.roles === ROLES.AGENT) return agentMenus;

  // return P_NAV
  return defaultMenus;
};
