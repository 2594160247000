import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { BETPLACED, BETPLACEDGRAND } from '../../helpers/constants';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

export default function ConfirmMajorJackpotBet({
  button_name: buttonName,
  button_label: buttonLabel,
  maxBet,
  minBet,
  onClose,
  isOpen,
  race: raceInfo,
  eventId,
  setRefreshRaceBet,
  socketPlayer,
  getPoints,
}) {
  const toast = useToast();
  const {
    handleSubmit,
    reset,
    setFocus,
    formState: { isSubmitting },
  } = useForm();
  const axiosPrivate = useAxiosPrivate();

  async function onSubmit(values) {
    toast.closeAll();
    if (isSubmitting) {
      return;
    }

    await axiosPrivate
      .post('bet/mega_jackpot', {
        eventId,
        raceId: raceInfo?.id,
      })
      .then(({ data }) => {
        handleOnClose();

        toast({
          id: `${raceInfo?.id}-${values.amount}`,
          title: data?.msg,
          description: '',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        setRefreshRaceBet(prev => !prev);
        socketPlayer.emit(BETPLACED);
        socketPlayer.emit(BETPLACEDGRAND);
      })
      .catch(err => {
        console.error(err);
        toast({
          id: `${raceInfo?.id}-${values.amount}`,
          title: err?.response?.data?.msg,
          description: '',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      })
      .finally(() => {
        getPoints();
      });

    // return new Promise(resolve => {
    //   setTimeout(() => {
    //     alert(JSON.stringify(values, null, 2));
    //     resolve();
    //   }, 3000);
    // });
  }

  const handleOnClose = () => {
    reset();
    onClose();
  };

  useEffect(() => {
    setFocus('amount');
  }, [setFocus]);

  return (
    <Drawer
      onClose={handleOnClose}
      isOpen={isOpen}
      placement="bottom"
      size="sm"
    >
      {/* <DrawerOverlay /> */}
      <DrawerContent>
        {/* <DrawerCloseButton colorScheme="red" /> */}
        {/* <DrawerHeader>{`drawer contents`}</DrawerHeader> */}
        <DrawerBody bg="blackAlpha.900" textAlign="center" minH="sm" pt={10}>
          <Box py={2}>
            <Text fontWeight="semibold" color="white" mb="4">
              BET MAJOR JACKPOT?
            </Text>

            <form onSubmit={handleSubmit(onSubmit)}>
              <Box textAlign="center" mt={2}>
                <Button
                  px={3}
                  mb={[3, 0]}
                  mr={[0, 4]}
                  w={['full', '32']}
                  type="submit"
                  colorScheme="brand"
                  isDisabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  YES
                </Button>
                <Button
                  w={['96%', '32']}
                  colorScheme="red"
                  onClick={handleOnClose}
                  isDisabled={isSubmitting}
                  type="button"
                >
                  NO
                </Button>
              </Box>
            </form>
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
