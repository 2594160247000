import {
  Box,
  Center,
  Heading,
  Image,
  ListItem,
  OrderedList,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tr,
  UnorderedList,
} from '@chakra-ui/react';
import logo from '../../assets/app_logo.png';

export default function RegularMechanics() {
  return (
    <Box p={4} w={['full', '2xl']} m="auto">
      <Center textAlign="center" mb={4}>
        <Image src={logo} w={['150px', '200px']} alt="MARBLE Color Game" />
      </Center>
      <Heading
        as="h1"
        size={['md', 'lg']}
        textAlign="center"
        sx={{ WebkitTextStroke: '0.5px white' }}
        color="rgb(91 155 213)"
        textShadow="1px 1px 1px rgb(157 195 230)"
      >
        REGULAR RACE
      </Heading>
      <Heading
        as="h2"
        mt="5"
        size="md"
        // sx={{ WebkitTextStroke: '0.5px white' }}
        color="yellow"
        // textShadow="1px 1px 1px rgb(157 195 230)"
      >
        GAME MECHANICS
      </Heading>
      <Box padding={4}>
        <OrderedList>
          <ListItem>
            At the start of every new race, a betting countdown commences.
            Select the color you wish to bet on and place your bet successfully.
          </ListItem>
          <ListItem>
            There are six (6) colors to choose in the betting console, that is,{' '}
            <Text as="span" color="red" fontWeight="bold">
              RED
            </Text>
            ,{' '}
            <Text as="span" color="blue" fontWeight="bold">
              BLUE
            </Text>
            ,{' '}
            <Text as="span" color="green" fontWeight="bold">
              GREEN
            </Text>{' '}
            ,{' '}
            <Text as="span" color="yellow" fontWeight="bold">
              YELLOW
            </Text>{' '}
            ,{' '}
            <Text as="span" color="rgb(255 153 254)" fontWeight="bold">
              PINK
            </Text>{' '}
            &{' '}
            <Text as="span" color="white" fontWeight="bold">
              WHITE
            </Text>
            .
          </ListItem>
          <ListItem>Each color will be placed in the Race Track.</ListItem>
          <ListItem>
            Each color ball on the Race Track is of equal size and almost has
            the same weight.
          </ListItem>
          <ListItem>
            No further bets will be accepted once the countdown ends in 20
            seconds.
          </ListItem>
          <ListItem>
            The game operator will release the balls, which will pass through
            the race track.
          </ListItem>
          <ListItem>
            If a ball falls out or tilts on the track during the race without
            human interference, the game will proceed.
          </ListItem>
          <ListItem>
            If a ball becomes stuck with others during the race, the operator
            will reposition the specific ball and release it again.
          </ListItem>
          <ListItem>
            The first color to reach the finish line after the second round of
            racing will be declared the winner of the race
          </ListItem>
          <ListItem>
            There is more or less two minutes interval between each race.
          </ListItem>
        </OrderedList>
      </Box>

      <Heading
        as="h2"
        mt="5"
        size="md"
        // sx={{ WebkitTextStroke: '0.5px white' }}
        color="yellow"
        // textShadow="1px 1px 1px rgb(157 195 230)"
      >
        PAYOUT/ODDS
      </Heading>
      <Box padding={4}>
        <OrderedList>
          <ListItem>
            The winning color will receive all the bets according to the odds.
          </ListItem>
          <ListItem>
            The system will automatically deduct the assigned percentage from
            the total winnings as the company’s commission.
          </ListItem>
          <ListItem>
            There is a minimum and maximum bet which will be set by the
            company/operator before the start of every event/race.
          </ListItem>
        </OrderedList>
        <Box display="flex" textAlign="center" width="full">
          <Box border="1px solid white" flex={1}>
            <Box borderBottom="1px solid white" p={1}>
              Explanation
            </Box>
            <Box>Bet on a color, and if the ball shows the chosen color</Box>
          </Box>
          <Box border="1px solid white">
            <Box borderBottom="1px solid white" p={1}>
              Odds/Payout
            </Box>
            <Box>6:1</Box>
          </Box>
        </Box>
      </Box>

      <Heading
        as="h3"
        mt="6"
        size="xs"
        // sx={{ WebkitTextStroke: '0.5px white' }}
        color="white"
        // textShadow="1px 1px 1px rgb(157 195 230)"
      >
        RETURN TO PLAYER: 100% Plus
      </Heading>
    </Box>
  );
}
