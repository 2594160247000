import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Image,
  Stack,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import ChipImage from '../../assets/chips150x150.png';
import commaSeparator from '../../helpers/commaSeparator';
import useAuth from '../../hooks/useAuth';
import { useState } from 'react';
import { ArrowDownIcon, ArrowUpIcon } from '@chakra-ui/icons';
import { CashIn, CashOut } from '../Buttons/CashButtons';

export default function PlayerFund() {
  const { auth } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [operation, setOperation] = useState('CashIn');

  const handleCashIn = () => {
    setOperation('Cash-In');
    onOpen();
  };

  const handleCashOut = () => {
    setOperation('Cash-Out');
    onOpen();
  };

  return (
    <>
      <HStack justifyContent="space-between" px={2} pb={2}>
        <Stack direction={['column', 'row']} gap={0}>
          <Text fontSize="sm" mr={1}>
            Current Balance:{' '}
          </Text>
          <HStack fontWeight="bold" gap={0}>
            <Image
              borderRadius="full"
              boxSize="20px"
              src={ChipImage}
              alt="Account Fund/Balance"
            />
            <Text>{commaSeparator(auth?.points)}</Text>
          </HStack>
        </Stack>

        <HStack>
          <CashIn w="90px" h="35px" onClick={handleCashIn} />
          <CashOut w="90px" h="35px" onClick={handleCashOut} />
          {/* <Button
            colorScheme="blue"
            size="xs"
            onClick={handleCashIn}
            mr={4}
            rightIcon={<ArrowDownIcon />}
          >
            Cash-IN
          </Button>
          <Button
            colorScheme="red"
            size="xs"
            onClick={handleCashOut}
            rightIcon={<ArrowUpIcon />}
          >
            Cash-OUT
          </Button> */}
        </HStack>
      </HStack>

      <Drawer placement="bottom" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">{operation}</DrawerHeader>
          <DrawerBody>
            <p>
              Coming soon...
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </p>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export function PlayerCash({ fontSize }) {
  const { auth } = useAuth();
  return (
    <HStack fontWeight="bold" gap={0} fontSize={fontSize}>
      <Image
        borderRadius="full"
        boxSize="25px"
        src={ChipImage}
        alt="Account Fund/Balance"
        mr={2}
      />
      <Text>{commaSeparator(auth?.points)}</Text>
    </HStack>
  );
}
