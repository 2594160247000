import ROLES from './roles';

export default function accountType(val = '') {
  let result = {
    isAgent: false,
    type: ROLES.PLAYER,
    level: null,
    showCommission: false,
    showPoints: true,
    showReferralLink: false,
  };

  if (val.includes('AGENT')) {
    result.isAgent = true;
    result.type = ROLES.AGENT;
    result.showCommission = true;
    result.level = val.substring(val.length - 1);
    result.showReferralLink = true;
  }

  if (val === ROLES.DECLARATOR) {
    result.isAgent = false;
    result.type = ROLES.DECLARATOR;
    result.showPoints = false;
  }

  if (val === ROLES.PLAYER) {
    result.isAgent = false;
    result.type = ROLES.PLAYER;
  }

  return result;
}
