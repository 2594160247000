import {
  Box,
  Button,
  Circle,
  Drawer,
  DrawerBody,
  DrawerContent,
  Grid,
  GridItem,
  HStack,
  Heading,
  Link,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import apiErrorHandler from '../../api/apiErrorHandler';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import Price from '../Price';
import { socketPlayer } from '../../api/socket';
import {
  BETPLACEDGRAND,
  MERCHANTID,
  PLAYERCOUNT,
  SITEID,
} from '../../helpers/constants';
import PagcorLogo from '../PagcorLogo';
import PlayerFund from './PlayerFund';
import { Link as ReactRouterLink } from 'react-router-dom';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import imgWin from '../../assets/YOUWIN_GRAND.png';
import commaSeparator from '../../helpers/commaSeparator';

export default function GrandJackpotBetting() {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [buttons, setButtons] = useState([]);
  const [raceList, setRaceList] = useState([]);
  const [myBets, setMybets] = useState([]);
  const [price, setPrice] = useState(0);
  const { eventId } = useParams();
  const [betDetails, setBetDetails] = useState([]);
  const { auth, setAuth } = useAuth();
  const [refresh, setRefresh] = useState(false);
  const [didWin, setDidWin] = useState({
    winning_amount: 0,
    win_status: '',
    playerid: 0,
  });

  const axiosPrivate = useAxiosPrivate();
  const toast = useToast();
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  const getPoints = async () => {
    await axiosPrivate
      .get('points')
      .then(({ data: { data } }) => {
        setAuth(prev => ({ ...prev, points: data?.[0]?.points ?? 0 }));
      })
      .catch(err => console.log(apiErrorHandler(err)));
  };

  async function onSubmit() {
    toast.closeAll();
    if (isSubmitting) {
      return;
    }

    await axiosPrivate
      .post('bet/grand_jackpot', { eventId, bet_details: betDetails })
      .then(({ data }) => {
        socketPlayer.emit(BETPLACEDGRAND);

        toast({
          title: data?.msg || 'Bet Accepted.',
          description: '',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      })
      .catch(err => {
        console.error(err);
        toast({
          id: `grandJackpot`,
          title: err?.response?.data?.msg || 'Bet Failed.',
          description: '',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      })
      .finally(() => {
        onClose();
        getPoints();
      });
  }

  const handleOnClose = () => {
    onClose();
  };

  const handleClick = (raceNumber, color) => {
    const data = [...betDetails];
    const selected = data.filter(
      item => Number(item.race_number) !== Number(raceNumber)
    );

    selected.push({
      race_number: raceNumber,
      bet_color: color.button_color,
      label: color.button_label,
    });

    setBetDetails(selected);
  };

  // useEffect(() => {
  //   const controller = new AbortController();
  //   const params = new URLSearchParams({ event: eventId });

  //   const getBetStatus = async () => {
  //     await axiosPrivate
  //       .get('bet/grand_bet/status', { params, signal: controller.signal })
  //       .then(({ data: { data } }) => {
  //         setDidWin(data);
  //       })
  //       .catch(err => console.log(apiErrorHandler(err)));
  //   };

  // getBetStatus();

  //   return () => {
  //     controller.abort();
  //   };
  // }, [axiosPrivate, eventId]);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    async function getEventsInfo() {
      const params = new URLSearchParams({ event: eventId });
      await axiosPrivate
        .get('event/jackpot/enter', { params, signal: controller.signal })
        .then(({ data }) => {
          const buttons = data?.button?.filter(
            btn => !btn.button_name.includes('JACKPOT')
          );
          isMounted && setButtons(buttons);
          isMounted && setRaceList(data?.race_list || []);
          isMounted && setPrice(data?.grand_jackpot_prize || 0);
          isMounted && setMybets(data?.myBet || []);
        })
        .catch(err => {
          const msg = apiErrorHandler(err);
          msg !== 'canceled' && console.error(msg);
        });
    }

    getEventsInfo();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [eventId, axiosPrivate, refresh, toast]);

  /* SOCKET */
  useEffect(() => {
    socketPlayer.io.opts.query = {
      ertyuhgvbniuhgbn: MERCHANTID,
      ertyuihgh: 1,
      msdfewaer: SITEID,
      usfsafsdnla: auth.user.username,
      isafdsafasp: auth.roles,
    };

    if (auth.user.username && auth.roles) {
      socketPlayer.open();
    }

    socketPlayer.on('connect', () => {
      // setSocketDisconnected(false);
      socketPlayer.sendBuffer = [];
    });

    socketPlayer.on('reconnecting', () => {
      // setRefreshBets(prev => !prev)
    });

    socketPlayer.on('connect_error', () => {
      // setSocketDisconnected(true);
    });

    socketPlayer.on(BETPLACEDGRAND, () => {
      setRefresh(prev => !prev);
    });

    return () => {
      socketPlayer.removeAllListeners();
      socketPlayer.disconnect();
    };
  }, [auth.user.username, auth.roles]);
  /* END SOCKET */

  return (
    <>
      <Box mt={4} maxW="md" margin="auto">
        <Box textAlign="end">
          <Button
            py={0}
            rightIcon={<ArrowForwardIcon />}
            colorScheme="teal"
            variant="ghost"
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
        </Box>
        <Price amount={price} winner={false} imgType="GRAND" />
        <Box mt={4}></Box>
        <PlayerFund />

        <Box px={4} pos="relative">
          <Grid mt={4} templateColumns="repeat(2, 1fr)" gap={4}>
            {raceList.map(item => (
              <CircleSelection
                handleClick={handleClick}
                race={item}
                buttons={buttons}
              />
            ))}
          </Grid>

          {betDetails.length < raceList.length ? (
            <Text mt={4}>* Please select a color per race!</Text>
          ) : null}
          <Box mt={4}>
            <Button
              px={3}
              mb={[3, 0]}
              mr={[0, 4]}
              w="full"
              type="button"
              colorScheme="brand"
              color="white"
              size="lg"
              onClick={onOpen}
            >
              BET ON GRAND JACKPOT
            </Button>
          </Box>
          {didWin?.win_status === 'WIN' ? (
            <VStack
              h="full"
              w="full"
              bgColor="blackAlpha.100"
              pos="absolute"
              top={0}
              left={0}
              justifyContent="flex-start"
            >
              <VStack
                h={['300px', '300px']}
                w={['300px', '300px']}
                bgImage={`url(${imgWin})`}
                bgPosition="center"
                bgRepeat="no-repeat"
                bgSize="contain"
                justifyContent="flex-end"
              >
                <Text
                  fontSize={['2xl', '4xl']}
                  pb={['14', '15%']}
                  fontWeight="bold"
                >
                  {commaSeparator(didWin?.winning_amount)}
                </Text>
              </VStack>
            </VStack>
          ) : null}
        </Box>

        <Box textAlign="center" mb={4}>
          <Button
            rightIcon={<ArrowForwardIcon />}
            colorScheme="teal"
            variant="ghost"
            onClick={() => navigate(-1)}
          >
            Back to Betting Console
          </Button>
        </Box>

        <Box mb={4} px={4}>
          <Heading as="h2" fontSize="md">
            RACE HISTORY
          </Heading>
          <HStack justifyContent="center" alignItems="flex-start">
            {raceList.map((item, index) => (
              <VStack key={`${index}-${item.fightNumber}`} gap={0}>
                <Box fontWeight="bold">{item.fightNumber}</Box>
                {item?.declare_win ? (
                  <Circle
                    display="inline-flex"
                    cursor={'pointer'}
                    h="25px"
                    w="25px"
                    bg={`url(/150x150pxballs/BALLS-${item?.declare_win}.png)`}
                    overflow="hidden"
                    bgSize="contain"
                  ></Circle>
                ) : (
                  <Box w="25px"></Box>
                )}
              </VStack>
            ))}
          </HStack>
        </Box>

        <Box px={4}>
          <Heading as="h2" fontSize="md">
            MY BETS
          </Heading>
          <Box>
            {myBets.map(bet => (
              <HStack
                justifyContent="center"
                alignItems="flex-start"
                borderBottomColor="gray.400"
                borderBottomWidth="1px"
                borderBottomStyle="solid"
              >
                {bet.map((item, index) => (
                  <VStack key={`${index}-${item.fightNumber}`} gap={0} mb={2}>
                    <Box fontWeight="bold">{item.fightNumber}</Box>
                    <Circle
                      display="inline-flex"
                      cursor={'pointer'}
                      h="25px"
                      w="25px"
                      bg={`url(/150x150pxballs/BALLS-${item?.bet_color}.png)`}
                      overflow="hidden"
                      bgSize="contain"
                    ></Circle>
                  </VStack>
                ))}
              </HStack>
            ))}
          </Box>
        </Box>

        <PagcorLogo />
      </Box>

      <Drawer
        onClose={handleOnClose}
        isOpen={isOpen}
        placement="bottom"
        size="sm"
      >
        {/* <DrawerOverlay /> */}
        <DrawerContent>
          {/* <DrawerCloseButton colorScheme="red" /> */}
          {/* <DrawerHeader>{`drawer contents`}</DrawerHeader> */}
          <DrawerBody bg="blackAlpha.900" textAlign="center" minH="sm" pt={10}>
            <Box py={2}>
              <Text fontWeight="semibold" color="white" mb="4">
                BET ON GRAND JACKPOT?
              </Text>

              <form onSubmit={handleSubmit(onSubmit)}>
                <Box textAlign="center" mt={2}>
                  <Button
                    px={3}
                    mb={[3, 0]}
                    mr={[0, 4]}
                    w={['full', '32']}
                    type="submit"
                    colorScheme="brand"
                    isDisabled={
                      isSubmitting || betDetails.length < raceList.length
                    }
                    isLoading={isSubmitting}
                  >
                    YES
                  </Button>
                  <Button
                    w={['96%', '32']}
                    colorScheme="red"
                    onClick={handleOnClose}
                    isDisabled={isSubmitting}
                    type="button"
                  >
                    NO
                  </Button>
                </Box>
                {betDetails.length < raceList.length ? (
                  <Text mt={1}>* Please select a color per race!</Text>
                ) : null}
              </form>
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

function CircleSelection({ handleClick, race, buttons }) {
  const [colorSelected, setColorSelected] = useState(null);

  const handleSelected = (raceNumber, color) => {
    setColorSelected(color.button_color);
    // const handleSelected = (raceNumber, { color, label }) => {
    //   setColorSelected({ color, label });
    handleClick(raceNumber, color);
  };
  return (
    <GridItem
      w="100%"
      borderRadius="md"
      bg={'gray.900'}
      border={'1px'}
      borderColor={colorSelected ? 'white' : 'red.400'}
    >
      <Box textAlign="center" fontWeight="bold" my={2}>
        Race {race.fightNumber}
      </Box>

      <Grid templateColumns="repeat(3, 1fr)" gap={1}>
        {buttons.map(color => (
          <GridItem textAlign="center">
            <Circle
              onClick={() => handleSelected(race.fightNumber, color)}
              display="inline-flex"
              cursor={'pointer'}
              h="35px"
              w="35px"
              bg={`url(/150x150pxballs/BALLS-${color.button_color}.png)`}
              bgSize="contain"
            >
              {colorSelected === color.button_color ? (
                <Text fontSize={'3xl'} color="#611821" fontWeight="bold">
                  X
                </Text>
              ) : null}
            </Circle>
          </GridItem>
        ))}
      </Grid>

      {/* {buttons.map(button => (
        <Grid templateColumns="repeat(3, 1fr)" gap={1}>
          {JSON.parse(button?.button_label).map(label => (
            <GridItem textAlign="center">
              <Circle
                onClick={() =>
                  handleSelected(race.fightNumber, {
                    color: button?.button_color,
                    label,
                  })
                }
                display="inline-flex"
                cursor={'pointer'}
                h="35px"
                w="35px"
                bg={`url(/150x150pxballs/${button.button_color}-${label}.png)`}
                bgSize="contain"
              >
                {colorSelected.color === button.button_color &&
                colorSelected.label === label ? (
                  <Text fontSize={'3xl'} color="#611821" fontWeight="bold">
                    X
                  </Text>
                ) : null}
              </Circle>
            </GridItem>
          ))}
        </Grid>
      ))} */}

      <Box textAlign="center" fontSize="xs" fontStyle="italic" mb={1}>
        (Tap Balls to select)
      </Box>
    </GridItem>
  );
}
