import { ArrowBackIcon } from "@chakra-ui/icons";
import { Alert, AlertDescription, AlertIcon, AlertTitle, Button, Container, Icon, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export default function NotFound() {
    const navigate = useNavigate()

    return <Container maxWidth={'container.lg'} pt={'4'}>
        <Alert
            status='info'
            variant='subtle'
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
            textAlign='center'
            height='250px'
        >
            <AlertIcon boxSize='40px' mr={0} />
            <AlertTitle mt={4} mb={1} fontSize='lg'>
                PAGE NOT FOUND
            </AlertTitle>
            <AlertDescription maxWidth='sm' mb={'4'}>
                <Text>Are you sure the website URL is correct?</Text>
                <Text>Get in touch with our support.</Text>
            </AlertDescription>
            <Button size={'sm'} rightIcon={<ArrowBackIcon />} colorScheme='blue' variant='outline' onClick={() => navigate(-1)} mb={'4'}>
                Go Back
            </Button>

        </Alert>
    </Container>
}