import { Image } from '@chakra-ui/react';
import imgLogo from '../assets/app_logo.png';

function Logo({ size = '100px' }) {
  return (
    <Image boxSize={size} objectFit={'scale-down'} src={imgLogo} alt="MARBLE" />
  );
}

export function LogoText({ sw = '2xs' }) {
  return <Image w={sw} objectFit="cover" src={imgLogo} alt="MARBLE" />;
}

export default Logo;
