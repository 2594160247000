import { HStack, Image, Text } from '@chakra-ui/react';
import PAGCORLogo from '../assets/2023PAGCORlogos.png';

export default function PagcorLogo() {
  return (
    <HStack justifyContent="center">
      <Text fontWeight="bold">Licensed By:</Text>
      <Image src={PAGCORLogo} alt="PAGCOR" w="60px" h="60px" />
    </HStack>
  );
}
