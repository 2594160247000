export const notAllowedBetting = new Set([
  'PENDING',
  'LOCK',
  'CLOSED',
  'CLOSE',
  'DONE',
  'CANCEL',
  'PAUSE',
]);

export const notAllowedMajorBetting = new Set([
  'LOCK',
  'CLOSED',
  'CLOSE',
  'DONE',
  'CANCEL',
]);
