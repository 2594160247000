import { Navigate } from 'react-router-dom';
import ROLES from '../helpers/roles';
import useAuth from '../hooks/useAuth';

export default function RedirectToUserDashboard() {
  const { auth } = useAuth();
  let dashboard = 'login';

  if (auth.roles === ROLES.PLAYER) dashboard = 'player';
  if (auth.roles === ROLES.AGENT) dashboard = '/agent';
  if (auth.roles === ROLES.PLATINUM) dashboard = '/games/8';
  if (auth.roles === ROLES.DECLARATOR) dashboard = '/declarator';

  return <Navigate to={dashboard} replace />;
}
