export const statusColor = status => {
  let color = 'black';
  switch (status) {
    case 'DONE':
    case 'OPEN':
      color = 'blue';
      break;

    case 'CLOSE':
    case 'CANCEL':
    case 'PENDING':
      color = 'red';
      break;

    default:
      color = 'initial';
      break;
  }

  return color;
};
