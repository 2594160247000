import { Box, Button, Flex, Text, useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import apiErrorHandler from '../../api/apiErrorHandler';
import buttonColor, { buttonFontColor } from '../../helpers/buttonColor';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { CURRENT_DECK_STATUS } from '../../helpers/constants';

export default function ButtonsAndDeclare({
  currentRace,
  setCurrentRace,
  eventButtons,
  socketDeclarator,
  setRefreshHistory,
}) {
  const [selectedColor, setSelectedColor] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const toast = useToast();
  console.log(currentRace);

  const handleFinalDeclare = async () => {
    setLoading(true);
    await axiosPrivate
      .post('declarator/race/declare', {
        raceId: currentRace?.id,
        declare_color:
          currentRace?.status === 'CANCEL' ? null : selectedColor?.button_name,
      })
      .then(({ data }) => {
        setRefreshHistory(prev => !prev);
        toast({
          title: 'Success',
          description: data?.msg,
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        if (currentRace?.status === 'CANCEL') {
          setCurrentRace(prev => {
            delete prev.id;
            return { ...prev };
          });
        }
      })
      .catch(err => {
        console.log(apiErrorHandler(err));
        toast({
          title: 'Failed',
          description: apiErrorHandler(err),
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      })
      .finally(() => {
        setLoading(false);
        socketDeclarator.emit(CURRENT_DECK_STATUS, {
          eventId: currentRace?.eventId,
          raceId: currentRace?.id,
        });
      });
  };

  useEffect(() => {
    if (currentRace?.status === 'OPEN') {
      setSelectedColor(null);
    }
  }, [currentRace?.status]);

  return (
    <Box p="2" mb="5">
      <Text fontWeight={'bold'} mb={2}>
        FINAL WINNING COLOR:{' '}
        <Text as="span" color={buttonColor(selectedColor?.button_color)}>
          {selectedColor?.button_color}{' '}
        </Text>
      </Text>
      <Flex spacing="4" mb="5" dir="row" flexWrap="wrap" gap="3">
        {eventButtons.map(button =>
          button?.isJackpot === 1 ? null : (
            <Button
              key={`${button?.id}`}
              w="90px"
              size="sm"
              bg={buttonColor(button?.button_color)}
              onClick={() => setSelectedColor(button)}
              isDisabled={
                isLoading ||
                currentRace?.status !== 'DONE' ||
                currentRace?.declare_win
              }
              color={buttonFontColor(button?.button_color)}
            >
              {button?.button_name}
            </Button>
          )
        )}
      </Flex>
      <Box>
        <Button
          w="full"
          size="md"
          variant="solid"
          colorScheme="red"
          onClick={handleFinalDeclare}
          isDisabled={
            isLoading ||
            currentRace?.status !== 'DONE' ||
            currentRace?.declare_win
          }
        >
          FINAL DECLARE
        </Button>
      </Box>
    </Box>
  );
}
