import {
  Alert,
  AlertIcon,
  Box,
  Heading,
  LinkBox,
  LinkOverlay,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import apiErrorHandler from '../../api/apiErrorHandler';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useAuth from '../../hooks/useAuth';
import ROLES from '../../helpers/roles';

export default function EventList() {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const [events, setEvents] = useState([]);
  const [errMsg, setErrMsg] = useState(null);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    async function getEvents() {
      setErrMsg(null);

      let url = 'event/race';

      if (auth?.roles === ROLES.DECLARATOR) {
        url = 'declarator/event_list';
      }

      await axiosPrivate
        .get(url, { signal: controller.signal })
        .then(({ data }) => {
          const { data: list } = data;
          isMounted && Array.isArray(list) && setEvents(list);
        })
        .catch(err => {
          const msg = apiErrorHandler(err);
          if (msg === 'canceled') return;
          setErrMsg(msg);
        });
    }

    getEvents();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [axiosPrivate, auth?.roles]);

  return (
    <Box p="5">
      <Heading size="lg" mb="5" color="purple.600">
        EVENTS
      </Heading>
      {errMsg ? (
        <Alert status="error">
          <AlertIcon />
          {errMsg}
        </Alert>
      ) : null}

      <Box display="flex" dir={['column', 'row']} flexWrap="wrap">
        {events.map(event => (
          <LinkBox
            _hover={{ bg: 'purple.500', color: 'white' }}
            key={event?.id}
            as="article"
            w="lg"
            p="5"
            mr="5"
            mb="5"
            borderWidth="1px"
            rounded="md"
          >
            <Heading size="md" my="1">
              <LinkOverlay
                as={Link}
                relative="path"
                to={`${
                  auth?.roles === ROLES.DECLARATOR
                    ? `/declarator/console/${event?.id}`
                    : `/console/${event?.id}`
                }`}
              >
                {event?.event_name}
              </LinkOverlay>
            </Heading>
            {/* <Text>
                    {event?.arena_name}
                </Text> */}
          </LinkBox>
        ))}
      </Box>
    </Box>
  );
}
