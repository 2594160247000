import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useLogout from '../hooks/useLogout';

const InactivityLogoutTimer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const logout = useLogout();

  const checkForInactivity = () => {
    const expireTimeString = localStorage.getItem('expireTime');
    const expireTime = expireTimeString ? parseInt(expireTimeString) : 0;

    if (expireTime < Date.now() && location.pathname !== '/') {
      logout();
      navigate('/login');
    }
  };

  const updateExpiryTime = () => {
    const expireTime = Date.now() + 1 * 60 * 60 * 1000;
    localStorage.setItem('expireTime', expireTime.toString());
  };

  useEffect(() => {
    const interval = setInterval(() => {
      checkForInactivity();
    }, 5000); // Check for inactivity every 5 seconds

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const events = ['click', 'keypress', 'scroll', 'mousemove'];

    const update = () => {
      updateExpiryTime();
    };

    events.forEach(event => window.addEventListener(event, update));

    return () => {
      events.forEach(event => window.removeEventListener(event, update));
    };
  }, []);

  return null;
};

export default InactivityLogoutTimer;

// src: https://dev.to/ingeniouswebster/enhancing-react-app-security-implementing-inactivity-logout-for-peace-of-mind-3nol
