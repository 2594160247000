import localforage from 'localforage';
import useAuth from './useAuth';

const useLogout = () => {
  const { setAuth } = useAuth();

  const logout = async () => {
    setAuth({});
    localforage.clear();
    // try {
    //   const response = await axios('/logout/user', { withCredentials: true })
    // } catch (err) {
    //   console.error(err)
    // }
  };
  return logout;
};

export default useLogout;
