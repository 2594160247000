import { Circle } from '@chakra-ui/layout';
import BetButtonColor from '../helpers/betButtonColor';

export default function GenerateCircle({ data }) {
  try {
    if (!data) {
      return null;
    }

    const arrData = JSON.parse(data);

    return arrData.map(item => (
      <Circle
        cursor={'pointer'}
        key={`pc-${item?.fightNumber}-${item?.color}`}
        h="27px"
        w="27px"
        // bg={BetButtonColor?.[item?.color]?.bg}
        // borderColor="gray.200"
        // borderWidth={'1px'}
        bgImage={`/150x150pxballs/BALLS-${item?.color}.png`}
        bgSize="contain"
        overflow="hidden"
        color={BetButtonColor?.[item?.color]?.textColor?.bola}
      >
        {item?.fightNumber}
      </Circle>
    ));
  } catch (error) {
    console.error(error);
  }

  return null;
}
