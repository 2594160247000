import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import Navigation from './Navigation';
import { SkeletonText } from '@chakra-ui/react';

export default function Dashboard() {
  return (
    <React.Fragment>
      <Navigation />
      <Suspense fallback={<SkeletonText mt="4" noOfLines={4} spacing="4" />}>
        <Outlet />
      </Suspense>
    </React.Fragment>
  );
}
