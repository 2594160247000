import io from 'socket.io-client';

const options = {
  autoConnect: false,
  rememberUpgrade: true,
  reconnectionDelay: 5000,
  transports: ['websocket', 'polling'],
};

export const socketPlayer = io(
  process.env.REACT_APP_SOCKET_URL_PLAYER,
  options
);
export const socketDeclarator = io(
  process.env.REACT_APP_SOCKET_URL_DECLARATOR,
  options
);

export default io;
