import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Image,
  Input,
  Stack,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  Stepper,
  StepSeparator,
  StepStatus,
  StepTitle,
  Text,
  Textarea,
  useSteps,
} from '@chakra-ui/react';
import moment from 'moment';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import logoImg from '../assets/app_logo.png';
import loginBgMobImg from '../assets/MOBILEBG.png';
import loginBgWebImg from '../assets/WEBBG.png';
import PagcorLogo from '../components/PagcorLogo';

const steps = [{ title: 'Info' }, { title: 'Account' }, { title: 'Success' }];

export default function Register() {
  const [details, setDetails] = useState({});
  const { activeStep, setActiveStep, goToNext } = useSteps({
    index: 1,
    count: 3,
  });
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: details,
  });

  const {
    handleSubmit: handleSubmit2,
    register: register2,
    reset: reset2,
    formState: { errors: errors2, isSubmitting: isSubmitting2 },
  } = useForm({
    defaultValues: details,
  });

  function onSubmitPersonal(values) {
    // const today = moment();
    // const _dob = moment(values.dob);
    // const diff = !today.isAfter(_dob);

    setDetails(prev => ({ ...prev, ...values }));
    // alert(JSON.stringify({ ...values, diff }, null, 2));
    goToNext();
  }

  function onSubmitAccount(values) {
    return new Promise(resolve => {
      setTimeout(() => {
        setDetails(prev => ({ ...prev, ...values }));
        alert(JSON.stringify(details, null, 2));
        goToNext();
        resolve();
      }, 3000);
    });
  }

  function handleReset() {
    setDetails({});
    reset();
    reset2();
    setActiveStep(1);
  }

  return (
    <Flex
      minH={'100vh'}
      align={{ base: 'flex-start', md: 'center' }}
      justify={'center'}
      bgRepeat={'no-repeat'}
      bgImage={[loginBgMobImg, loginBgWebImg]}
      bgSize="contain"
      bgPos={'center'}
    >
      <Stack
        spacing={2}
        mx={'auto'}
        minW="sm"
        maxW="lg"
        pb={12}
        px={2}
        pt={{ base: '10', md: '0' }}
      >
        <PagcorLogo />
        <Stack align={'center'}>
          <Image
            objectFit="cover"
            src={logoImg}
            alt="MARBLE"
            w={['3xs', '2xs']}
          />
          <Stack spacing={4} w="full">
            <Heading as="h1" size="lg" noOfLines={1} textAlign="center">
              REGISTER
            </Heading>
            <Stepper index={activeStep} orientation="horizontal" size="xs">
              {steps.map((step, index) => (
                <Step key={1}>
                  <StepIndicator>
                    <StepStatus
                      complete={<StepIcon />}
                      incomplete={<StepNumber />}
                      active={<StepNumber />}
                    />
                  </StepIndicator>

                  <Box flexShrink="0">
                    <StepTitle>{step.title}</StepTitle>
                    {/* <StepDescription>Personal Information</StepDescription> */}
                  </Box>
                  <StepSeparator />
                </Step>
              ))}
            </Stepper>

            {activeStep === 1 ? (
              <Box
                background="#00000026"
                backdropFilter="blur(20px)"
                borderRadius="sm"
                boxShadow="0 8px 32px rgba(0, 0, 0, 0.1)"
                p={2}
              >
                <form
                  id="formPersonal"
                  onSubmit={handleSubmit(onSubmitPersonal)}
                >
                  <FormControl isInvalid={errors.first_name}>
                    <FormLabel htmlFor="first_name">First name</FormLabel>
                    <Input
                      id="first_name"
                      placeholder="First Name"
                      {...register('first_name', {
                        required: 'This is required',
                        minLength: {
                          value: 2,
                          message: 'Minimum length should be 2',
                        },
                      })}
                    />
                    <FormErrorMessage>
                      {errors.first_name && errors.first_name.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.last_name}>
                    <FormLabel htmlFor="first_name">Last name</FormLabel>
                    <Input
                      id="last_name"
                      placeholder="Last Name"
                      {...register('last_name', {
                        required: 'This is required',
                        minLength: {
                          value: 2,
                          message: 'Minimum length should be 2',
                        },
                      })}
                    />
                    <FormErrorMessage>
                      {errors.last_name && errors.last_name.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.address}>
                    <FormLabel htmlFor="address">Address</FormLabel>
                    <Textarea
                      id="address"
                      placeholder="Address"
                      {...register('address', {
                        required: 'This is required',
                        minLength: {
                          value: 5,
                          message: 'Minimum length should be 5',
                        },
                        maxLength: {
                          value: 150,
                          message: 'Max length should be 150',
                        },
                      })}
                      size="sm"
                      resize="vertical"
                    />
                    <FormErrorMessage>
                      {errors.address && errors.address.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.dob}>
                    <FormLabel htmlFor="dob">Date of Birth</FormLabel>
                    <Input
                      id="dob"
                      placeholder="Date of Birth"
                      {...register('dob', {
                        required: 'This is required',
                        validate: {
                          futureDate: v => {
                            const today = moment();
                            const _dob = moment(v);
                            return (
                              today.isAfter(_dob) || "Date shouldn't be future"
                            );
                          },
                          lessThan18yr: v => {
                            const today = moment();
                            const _dob = moment(v);
                            return (
                              today.diff(_dob, 'years') >= 18 ||
                              'You must be 18 years of age to register'
                            );
                          },
                        },
                      })}
                      type="date"
                    />
                    <FormErrorMessage>
                      {errors.dob && errors.dob.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.phone}>
                    <FormLabel htmlFor="phone">Mobile Number</FormLabel>
                    <Input
                      id="phone"
                      placeholder="Mobile Number"
                      {...register('phone', {
                        required: 'This is required',
                        pattern: {
                          value: /^\d+$/,
                          message: 'Number only',
                        },
                        minLength: {
                          value: 10,
                          message: 'Minimum length should be 10',
                        },
                        maxLength: {
                          value: 11,
                          message: 'Max length should be 11',
                        },
                      })}
                    />
                    <FormErrorMessage>
                      {errors.phone && errors.phone.message}
                    </FormErrorMessage>
                  </FormControl>

                  <Button
                    mt={4}
                    colorScheme="teal"
                    isLoading={isSubmitting}
                    type="submit"
                    form="formPersonal"
                  >
                    Next
                  </Button>
                </form>
              </Box>
            ) : null}

            {activeStep === 2 ? (
              <Box
                background="#00000026"
                backdropFilter="blur(20px)"
                borderRadius="sm"
                boxShadow="0 8px 32px rgba(0, 0, 0, 0.1)"
                p={2}
              >
                <form
                  id="formAccount"
                  onSubmit={handleSubmit2(onSubmitAccount)}
                >
                  <Text as="i">
                    Please enter your desired username and password.
                  </Text>
                  <FormControl isInvalid={errors2.username} mt={2}>
                    <FormLabel htmlFor="username">Username</FormLabel>
                    <Input
                      id="username"
                      placeholder="Username"
                      {...register2('username', {
                        required: 'This is required',
                        pattern: {
                          value: /^\S*$/,
                          message: 'Whitespace not allowed',
                        },
                        minLength: {
                          value: 4,
                          message: 'Minimum length should be 4',
                        },
                        maxLength: {
                          value: 25,
                          message: 'Max length should be 25',
                        },
                      })}
                    />
                    <FormErrorMessage>
                      {errors2.username && errors2.username.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors2.password}>
                    <FormLabel htmlFor="password">Password</FormLabel>
                    <Input
                      id="password"
                      placeholder="Password"
                      {...register2('password', {
                        required: 'This is required',
                        pattern: {
                          value: /^\S*$/,
                          message: 'Whitespace not allowed',
                        },
                        minLength: {
                          value: 4,
                          message: 'Minimum length should be 4',
                        },
                        maxLength: {
                          value: 25,
                          message: 'Max length should be 25',
                        },
                      })}
                    />
                    <FormErrorMessage>
                      {errors2.password && errors2.password.message}
                    </FormErrorMessage>
                  </FormControl>

                  <Button
                    mt={4}
                    colorScheme="teal"
                    isLoading={isSubmitting2}
                    type="submit"
                    form="formAccount"
                  >
                    Submit
                  </Button>
                </form>
              </Box>
            ) : null}
            {activeStep === 3 ? (
              <Alert
                status="success"
                variant="subtle"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                height="200px"
              >
                <AlertIcon boxSize="40px" mr={0} />
                <AlertTitle mt={4} mb={1} fontSize="lg">
                  Application submitted!
                </AlertTitle>
                <AlertDescription maxWidth="sm">
                  Thanks for submitting your application. Our team will get back
                  to you soon.
                </AlertDescription>
              </Alert>
            ) : null}
          </Stack>
        </Stack>
      </Stack>
    </Flex>
  );
}
