import {
  Box,
  Circle,
  Flex,
  Link as ChakraLink,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  Wrap,
  WrapItem,
  Button,
  useToast,
  Tooltip,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import apiErrorHandler from '../../api/apiErrorHandler';
import { Link as ReactRouterLink } from 'react-router-dom';
import { CURRENT_DECK_STATUS } from '../../helpers/constants';
import { statusColor } from '../../helpers/statusColor';

export default function History({
  eventId,
  setEventRaceHistory,
  refreshHistory,
  eventButtons,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const axiosPrivate = useAxiosPrivate();
  const [raceHistory, setRaceHistory] = useState([]);
  const [selected, setSelected] = useState({});
  const [refreshRecord, setRefreshRecord] = useState(false);

  function fontColor(color) {
    if (color === 'BLUE' || color === 'RED' || color === 'GREEN')
      return 'white';
    return 'black';
  }

  const handleChangeColor = selected => {
    console.log(selected);
    setSelected(selected);
    onOpen();
  };

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    async function getEventHistory() {
      const params = new URLSearchParams({ event: eventId });
      await axiosPrivate
        .get(`declarator/race/pick_color`, {
          params,
          signal: controller.signal,
        })
        .then(({ data }) => {
          if (Array.isArray(data?.data)) {
            isMounted && setRaceHistory(data?.data || []);
            isMounted && setEventRaceHistory(data?.data || []);
          }
        })
        .catch(err => {
          console.error(apiErrorHandler(err));
        })
        .finally(() => {});
    }

    getEventHistory();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [
    eventId,
    axiosPrivate,
    setEventRaceHistory,
    refreshHistory,
    refreshRecord,
  ]);

  return (
    <Box>
      <Text fontWeight={'bold'}>HISTORY</Text>
      <Box borderRadius="md" border="1px" px={5} py={2} borderColor="gray.100">
        <Table>
          <Thead>
            <Tr>
              <Th>#</Th>
              <Th w="full">Race Results</Th>
              <Th>Win/Status</Th>
            </Tr>
          </Thead>
          <Tbody>
            {raceHistory.map((item, index) => (
              <Tr key={`outer-${item?.raceDetails?.f_no}`}>
                <Td>
                  <ChakraLink
                    as={ReactRouterLink}
                    to={`/declarator/console/${eventId}/${item?.raceDetails?.f_no}`}
                  >
                    {item?.raceDetails?.f_no}
                  </ChakraLink>
                </Td>
                <Td>
                  {(item?.color || []).map(bola => (
                    <Circle
                      display="inline-flex"
                      cursor={'pointer'}
                      key={`pc-${item?.raceDetails?.f_no}-${bola?.id}`}
                      h="25px"
                      w="25px"
                      m={1}
                      // bg={bola?.color}
                      // borderColor="gray.200"
                      // borderWidth={'1px'}
                      overflow="hidden"
                      bgImage={`/150x150pxballs/${bola?.color}-${bola?.label}.png`}
                      bgSize="contain"
                      onClick={handleChangeColor.bind(this, {
                        ...bola,
                        pickId: bola?.id,
                        raceId: item?.raceDetails?.raceId,
                      })}
                    >
                      {/* <Text color={fontColor(bola?.color)}>
                          {bola?.label}
                        </Text> */}
                    </Circle>
                  ))}
                </Td>
                <Td>
                  <Center width="full">
                    {item?.raceDetails?.declare_win?.color ? (
                      <Circle
                        cursor={'pointer'}
                        key={`pc-${item?.raceDetails?.f_no}-${index}`}
                        h="25px"
                        w="25px"
                        // bg={item?.raceDetails?.declare_win}
                        // borderColor="gray.200"
                        // borderWidth={'1px'}
                        bgImage={`/150x150pxballs/BALLS-${item?.raceDetails?.declare_win?.color}.png`}
                        bgSize="contain"
                      ></Circle>
                    ) : (
                      <Text
                        fontSize="xs"
                        color={statusColor(item?.raceDetails?.status)}
                      >
                        {item?.raceDetails?.status}
                      </Text>
                    )}
                  </Center>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
      <ChangeDropColor
        selected={selected}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        eventButtons={eventButtons}
        setRefreshRecord={setRefreshRecord}
      />
    </Box>
  );
}

function ChangeDropColor({
  onOpen,
  onClose,
  isOpen,
  eventButtons = [],
  selected,
  setRefreshRecord,
}) {
  const axiosPrivate = useAxiosPrivate();
  const toast = useToast();

  const handleChange = async newColor => {
    toast.closeAll();
    console.log(newColor);

    if (
      window.confirm(`You selected ${newColor.declare_color}, confirm?`) ===
      true
    ) {
      await axiosPrivate
        .put('declarator/race/pick_color', {
          ...selected,
          ...newColor,
        })
        .then(({ data }) => {
          setRefreshRecord(prev => !prev);
          toast({
            title: 'Success',
            status: 'success',
            description: 'Selected successfully saved.',
          });
        })
        .catch(err => {
          const errMsg = apiErrorHandler(err);
          toast({
            title: 'Failed',
            status: 'warning',
            description: errMsg,
          });
        })
        .finally(() => {
          onClose();
        });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      size={'sm'}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>CHANGE</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb={'4'}>
            Change{' '}
            <Text fontWeight={'bold'} as="span">
              {selected?.color} - {selected?.label}
            </Text>{' '}
            to:
          </Text>
          <Wrap spacing="4" mb="5" align="center">
            {eventButtons.map(button => (
              <GenerateButton
                key={button?.id}
                {...button}
                selectedColor={selected?.color}
                selectedLabel={selected?.label}
                handleChange={handleChange}
              />
            ))}
          </Wrap>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

function GenerateButton({
  id,
  button_label,
  button_color,
  button_name,
  isDisabled,
  isJackpot,
  selectedLabel,
  selectedColor,
  handleChange,
}) {
  if (isJackpot) return null;

  try {
    const labels = JSON.parse(button_label.replace(/'/g, '"'));

    return (labels || []).map(label =>
      isJackpot ? null : (
        <WrapItem key={`${id}${label}`} minW="90px" textAlign="center">
          {/* <Button
            w="full"
            size="sm"
            colorScheme={buttonColor(button_color)}
            isDisabled={isDisabled}
            onClick={() =>
              handleChange({
                declare_color: button_color,
                declare_label: label,
              })
            }
          >
            {label}
          </Button> */}

          <Circle
            h="50px"
            w="50px"
            bgImage={`/150x150pxballs/BALLS-${button_color}.png`}
            bgSize="contain"
            cursor={selectedColor === button_color ? 'not-allowed' : 'pointer'}
            filter={
              selectedColor === button_color
                ? 'grayscale(90%)'
                : 'grayscale(0%)'
            }
            onClick={
              selectedColor === button_color
                ? () => {}
                : () =>
                    handleChange({
                      declare_color: button_color,
                      declare_label: selectedLabel,
                    })
            }
          ></Circle>
        </WrapItem>
      )
    );
  } catch (error) {
    console.error(error);
    // alert('Cannot generate buttons.')
    return null;
  }
}
