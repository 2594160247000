import {
  Box,
  Button,
  HStack,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
  useBoolean,
  useToast,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import apiErrorHandler from '../../api/apiErrorHandler';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { CURRENT_DECK_STATUS } from '../../helpers/constants';

export default function CreateRace({
  eventId,
  currentRace,
  setCurrentRace,
  socketDeclarator,
}) {
  const [raceNumber, setRaceNumber] = useState(currentRace?.fightNumber || 1);
  const axiosPrivate = useAxiosPrivate();
  const [edit, setEdit] = useBoolean();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  const handleCreateRace = async () => {
    setIsLoading(true);
    toast.closeAll();

    await axiosPrivate
      .post('declarator/race/new', {
        eventId,
        raceNumber,
        current_raceNumber: currentRace?.fightNumber,
      })
      .then(({ data }) => {
        socketDeclarator.emit(CURRENT_DECK_STATUS, {
          eventId,
          raceId: data?.raceId,
        });
        setCurrentRace(prev => ({
          ...prev,
          fightNumber: raceNumber,
          id: data?.raceId,
          status: 'PENDING',
        }));
        setEdit.toggle();

        toast({
          title: 'Success',
          description: `Race ${raceNumber} created/updated.`,
          status: 'success',
          duration: 9000,
          isClosable: true,
        });

        navigate(`/declarator/console/${eventId}/${raceNumber}`, {
          replace: true,
        });
      })
      .catch(err => {
        const msg = apiErrorHandler(err);

        if (msg === 'Race number is already in used') {
          const raceInfo = err.response?.data?.race_info;
          socketDeclarator.emit(CURRENT_DECK_STATUS, {
            eventId,
            raceId: raceInfo?.id,
          });
          setCurrentRace(prev => ({
            ...prev,
            fightNumber: raceNumber,
            id: raceInfo?.id,
            status: raceInfo?.status,
          }));

          setEdit.toggle();
          navigate(`/declarator/console/${eventId}/${raceNumber}`, {
            replace: true,
          });

          return;
        }

        if (msg === 'Race number is not yet declared,Please declare!') {
          const raceInfo = err.response?.data?.race_info;
          socketDeclarator.emit(CURRENT_DECK_STATUS, {
            eventId,
            raceId: raceInfo?.id,
          });
          setCurrentRace(prev => ({
            ...prev,
            fightNumber: raceNumber,
            id: raceInfo?.id,
            status: raceInfo?.status,
          }));
          setEdit.toggle();
        }

        toast({
          title: 'Failed',
          description: apiErrorHandler(err),
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setRaceNumber(currentRace?.fightNumber || 1);
  }, [currentRace?.fightNumber]);

  return (
    <HStack>
      <Text>Race#:</Text>
      <Box>
        <HStack display={edit ? 'none' : 'flex'}>
          <Text as="b" fontSize="3xl">
            {raceNumber}
          </Text>
          <Button
            colorScheme="teal"
            variant="link"
            size="sm"
            onClick={setEdit.toggle}
          >
            Change
          </Button>
        </HStack>
        <HStack display={edit ? 'flex' : 'none'}>
          <NumberInput
            w="28"
            min={1}
            defaultValue={currentRace?.fightNumber || 1}
            onChange={value => setRaceNumber(value)}
            value={raceNumber}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <Button
            colorScheme="teal"
            variant="solid"
            size="sm"
            onClick={handleCreateRace}
            isDisabled={isLoading}
            isLoading={isLoading}
            loadingText="Please wait..."
          >
            Save
          </Button>
          <Button
            colorScheme="teal"
            variant="outline"
            size="sm"
            isDisabled={isLoading || !currentRace?.id}
            onClick={() => {
              setEdit.toggle();
              setRaceNumber(currentRace?.fightNumber || 1);
            }}
          >
            Cancel
          </Button>
        </HStack>
      </Box>
    </HStack>
  );
}
