import { Box, HStack, Image, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import imgBg from '../../assets/COLORBOX-GRAND.png';
import chip1 from '../../assets/casino-chip1.png';
import chip2 from '../../assets/casino-chip2.png';
import commaSeparator from '../../helpers/commaSeparator';

export default function BetButtonGrandJackpot({
  prize = 0,
  mybet = 0,
  payout = 0,
  refreshData,
  event,
}) {
  const navigate = useNavigate();

  return (
    <Box
      bgImage={imgBg}
      py={4}
      borderRadius="sm"
      onClick={() => navigate(`/console/jackpot/${event?.id}`)}
      overflow="hidden"
      bgSize="100% 100%"
      bgPos="center"
      bgRepeat="no-repeat"
      w="full"
    >
      <Text textAlign="center" fontWeight="bold" fontSize="xs">
        GRAND JACKPOT
      </Text>
      <Text
        fontFamily="Impact, Times, serif"
        fontWeight="medium"
        textAlign="center"
        fontSize="2xl"
        letterSpacing={1}
      >
        {commaSeparator(prize || 0)}
      </Text>
      <HStack justifyContent="center" lineHeight="none" gap={0}>
        {/* <HStack justifyContent="flex-end" gap="0.1rem" flex={1}>
          <Image src={chip1} alt="BET" width="12px" mr="3px" />
          <Text fontSize="sm">MY BET</Text>
        </HStack>
        <Text>:</Text> */}
        <Image src={chip1} alt="BET" width="12px" mr="1px" />
        <Text fontSize="md" fontWeight="medium">
          {commaSeparator(mybet || 0)}
        </Text>
      </HStack>
      <HStack justifyContent="center" gap={0}>
        {/* <HStack justifyContent="flex-end" gap="0.1rem" flex={1}>
          <Image src={chip2} alt="BET" width="12px" />
          <Text fontSize="sm">PAYOUT</Text>
        </HStack>
        <Text>:</Text> */}
        <Image src={chip2} alt="BET" width="12px" mr="2px" />
        {/* <Text fontSize="md" fontWeight="medium" >  */}
        <Text fontSize="md" fontWeight="medium">
          {commaSeparator(payout || 0)}
        </Text>
      </HStack>

      <Text
        fontFamily="Arial, Helvetica, sans-serif"
        fontStyle="italic"
        textAlign="center"
        fontSize="2xs"
      >
        &#40;Tap to Bet&#41;
      </Text>
    </Box>
  );
}
