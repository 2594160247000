import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  HStack,
  useDisclosure,
} from '@chakra-ui/react';
import imgCashIn from '../../assets/CASH-IN.png';
import imgCashOut from '../../assets/CASH-OUT.png';
import { useRef, useState } from 'react';

export default function CashButtons() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();
  const [whichBtn, setWhichBtn] = useState(null);

  const handleButtonClick = btn => {
    setWhichBtn(btn);
    onOpen();
  };

  return (
    <>
      <HStack justifyContent="center">
        <CashIn onClick={handleButtonClick.bind(this, 'Cash-In')} />
        <CashOut onClick={handleButtonClick.bind(this, 'Cash-Out')} />
      </HStack>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>{whichBtn}</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>Coming soon.</AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose} colorScheme="red">
              Okey
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}

export function CashIn({ w = '150px', h = '54px', onClick = null }) {
  return (
    <Box
      cursor="pointer"
      bgImage={imgCashIn}
      w={w}
      h={h}
      bgPos="center"
      bgSize="100% 100%"
      onClick={onClick}
    />
  );
}

export function CashOut({ w = '150px', h = '54px', onClick = null }) {
  return (
    <Box
      cursor="pointer"
      bgImage={imgCashOut}
      w={w}
      h={h}
      bgPos="center"
      bgSize="100% 100%"
      onClick={onClick}
    />
  );
}
