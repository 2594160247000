import {
  Box,
  Center,
  Circle,
  Grid,
  GridItem,
  HStack,
  Image,
  Text,
} from '@chakra-ui/react';
import { useCallback } from 'react';
import BetButtonColor from '../../helpers/betButtonColor';
import parseLabel from '../../helpers/parseLabel';
import commaSeparator from '../../helpers/commaSeparator';
import redBox from '../../assets/COLORBOX-RED.png';
import blueBox from '../../assets/COLORBOX-BLUE.png';
import greenBox from '../../assets/COLORBOX-GREEN.png';
import yellowBox from '../../assets/COLORBOX-YELLOW.png';
import pinkBox from '../../assets/COLORBOX-PINK.png';
import whiteBox from '../../assets/COLORBOX-WHITE.png';
import chip1 from '../../assets/casino-chip1.png';
import chip from '../../assets/casino-chip.png';

export default function BetButton({
  onClick,
  button_label: buttonLabel,
  button_name: buttonName,
  total_bet: totalBet,
  my_total_bet: myBet,
  winning_points: winning,
  odds,
  id,
  button_color: buttonColor,
}) {
  const getBg = useCallback(() => {
    if (buttonName === 'RED') return redBox;
    if (buttonName === 'BLUE') return blueBox;
    if (buttonName === 'GREEN') return greenBox;
    if (buttonName === 'YELLOW') return yellowBox;
    if (buttonName === 'PINK') return pinkBox;
    if (buttonName === 'WHITE') return whiteBox;
  }, [buttonName]);

  return (
    <Box
      py={4}
      onClick={onClick}
      overflow="hidden"
      bgImage={getBg()}
      bgSize="99% 100%"
      bgPos="center"
      bgRepeat="no-repeat"
      fontFamily="Arial, Helvetica, sans-serif"
      color={BetButtonColor?.[buttonColor]?.textColor.console}
    >
      {/* <Bola buttonLabel={buttonLabel} buttonColor={buttonColor} /> */}
      {/* <Center>
        <Circle
          w="28px"
          h="28px"
          // fontSize="xs"
          bgImage={`/150x150pxballs/BALLS-${buttonColor}.png`}
          bgSize="contain"
        ></Circle>
      </Center> */}

      <Text
        fontFamily="Impact, Times, serif"
        fontWeight="medium"
        textAlign="center"
        fontSize="m"
        letterSpacing={1}
      >
        {commaSeparator(totalBet || 0)}
      </Text>
      <Box>
        <HStack justifyContent="center" gap="0" lineHeight="none">
          <HStack justifyContent="center" gap="0" flex={1}>
            <Image src={chip1} alt="BET" width="10px" mr="1px" />
            <Text fontSize="sm">
              {/* MY BET:{' '} */}
              <Text as="span" fontSize="md" flex={1} fontWeight="medium">
                {commaSeparator(myBet || 0)}
              </Text>
            </Text>
          </HStack>
        </HStack>

        <HStack justifyContent="center" gap="0">
          <HStack justifyContent="center" gap="0.1rem" flex={1}>
            <Image src={chip} alt="BET" width="10px" />
            <Text fontSize="sm">
              {/* PAYOUT:{' '} */}
              <Text as="span" fontSize="md" flex={1} fontWeight="medium">
                {commaSeparator(winning || 0)}
              </Text>
            </Text>
          </HStack>
        </HStack>
      </Box>
      <Text fontStyle="italic" textAlign="center" fontSize="0.5rem">
        &#40;Tap to Bet&#41;
      </Text>
    </Box>
  );
}

function Bola({ buttonLabel, buttonColor }) {
  try {
    const labels = parseLabel(buttonLabel);
    return (
      <HStack justifyContent="center" gap="1">
        {labels.map(i => (
          <Circle
            key={i}
            // boxShadow="inner"
            // bg={BetButtonColor?.[buttonColor]?.ballBg || 'white'}
            // bgGradient={`radial(${BetButtonColor?.[buttonColor]?.bola.from}, ${BetButtonColor?.[buttonColor]?.bola.to})`}
            // color={BetButtonColor?.[buttonColor]?.textColor?.bola}
            w="28px"
            h="28px"
            // fontSize="xs"
            bgImage={`/150x150pxballs/${buttonColor}-${i}.png`}
            bgSize="contain"
          ></Circle>
        ))}
      </HStack>
    );
  } catch (error) {
    console.error(error);
  }
  return null;
}
