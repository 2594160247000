import { Text } from '@chakra-ui/react';
import Marquee from 'react-fast-marquee';

export default function Announcement({ announcement }) {
  return (
    <Marquee>
      <Text fontWeight="medium">{announcement}</Text>
    </Marquee>
  );
}
