const buttonColor = color => {
  if (color === 'WHITE' || typeof color === 'undefined') return 'white';
  return String(color).toLowerCase();
};

const buttonFontColor = color => {
  if (
    color === 'YELLOW' ||
    color === 'PINK' ||
    color === 'WHITE' ||
    typeof color === 'undefined'
  )
    return 'black';

  return 'white';
};

export { buttonColor as default, buttonColor, buttonFontColor };
