import {
  Box,
  Circle,
  HStack,
  Heading,
  SkeletonText,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

export default function EventHistory() {
  const axiosPrivate = useAxiosPrivate();
  const [isLoading, setIsLoading] = useState(true);
  const [list, setList] = useState([]);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    async function getList() {
      setIsLoading(true);

      await axiosPrivate
        .get('event/history', { signal: controller.signal })
        .then(({ data: { data = [] } }) => {
          isMounted && setList(data);
        })
        .catch(err => {
          console.error(
            err.response?.data?.msg || 'Failed to retrieve records.'
          );
        })
        .finally(() => setIsLoading(false));
    }

    getList();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [axiosPrivate]);

  return (
    <Box p={4}>
      <Heading as="h1" fontSize="lg">
        Event History
      </Heading>

      <Box
        overflowX={['scroll', 'hidden']}
        maxW={['full', '2xl']}
        margin="auto"
      >
        <Table>
          <Thead>
            <Tr>
              <Th>Event Name</Th>
              <Th>Race History</Th>
            </Tr>
          </Thead>
          <Tbody>
            {list.length <= 0 && !isLoading ? (
              <Tr>
                <Td textAlign="center" colSpan={2}>
                  No record.
                </Td>
              </Tr>
            ) : null}

            {isLoading ? (
              <Tr>
                <Td textAlign="center" colSpan={2}>
                  <SkeletonText
                    mt="4"
                    noOfLines={4}
                    spacing="4"
                    skeletonHeight="2"
                  />
                </Td>
              </Tr>
            ) : (
              list.map(event => (
                <Tr>
                  <Td paddingLeft={0}>
                    <Text whiteSpace="nowrap">{event?.event_name}</Text>
                  </Td>
                  <Td paddingX={1}>
                    <HStack justifyContent="flex-start" alignItems="flex-start">
                      {(event?.history || []).map((item, index) => (
                        <VStack key={`${index}-${item.fightNumber}`} gap={0}>
                          <Box fontWeight="medium">{item.fightNumber}</Box>
                          {item?.declare_win ? (
                            <Circle
                              display="inline-flex"
                              cursor={'pointer'}
                              h="25px"
                              w="25px"
                              bg={`url(/150x150pxballs/BALLS-${item?.declare_win}.png)`}
                              overflow="hidden"
                              bgSize="contain"
                            ></Circle>
                          ) : (
                            <Box w="25px"></Box>
                          )}
                        </VStack>
                      ))}
                    </HStack>
                  </Td>
                </Tr>
              ))
            )}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
}
