import {
  AbsoluteCenter,
  Box,
  Button,
  Center,
  Grid,
  GridItem,
  HStack,
  Heading,
  Image,
  Stack,
  Text,
  VStack,
  keyframes,
  useDisclosure,
} from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import apiErrorHandler from '../../api/apiErrorHandler';
import { socketPlayer } from '../../api/socket';
import BetButtonColor from '../../helpers/betButtonColor';
import {
  BETPLACED,
  CURRENT_DECK_STATUS,
  MERCHANTID,
  SITEID,
} from '../../helpers/constants';
import {
  notAllowedBetting,
  notAllowedMajorBetting,
} from '../../helpers/notAllowedBetting';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import Announcement from '../Announcement';
import PagcorLogo from '../PagcorLogo';
import VideoPlayer from '../videoplayers/VideoPlayer';
import BetButton from './BetButton';
import BetButtonMajorJackpot from './BetButtonMajorJackpot';
import ConfirmBetDialog from './ConfirmBetDialog';
import ConfirmMajorJackpotBet from './ConfirmMajorJackpotBet';
import PlayerFund from './PlayerFund';
import RaceHistory from './RaceHistory';
import BetButtonGrandJackpot from './BetButtonGrandJackpot';
import BetButtonMegaJackpot from './BetButtonMegaJackpot';
import Tally2 from './Tally2';
import imgWin from '../../assets/YOUWIN.png';
import imgWinMajor from '../../assets/YOUWIN_MAJOR.png';
import imgWinGrand from '../../assets/YOUWIN_GRAND.png';
import commaSeparator from '../../helpers/commaSeparator';
import GrandMajorWinImg from './Wins/GrandMajorWinImg';
import WinImg from './Wins/WinImg';
import StartImg from './Wins/StartImg';

export default function Console() {
  const { eventId, raceId } = useParams();
  const { auth, setAuth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const [selectedBetButton, setSelectedBetButton] = useState(null);
  const [refreshRaceInfo, setRefreshRaceInfo] = useState(false);
  const [refreshRaceBet, setRefreshRaceBet] = useState(false);
  const [loading, setLoading] = useState(true);
  const [announcement, setAnnouncement] = useState('');
  const [historyPercentage, setHistoryPercentage] = useState([]);

  const navigate = useNavigate();
  const [eventInfo, setEventInfo] = useState({});
  const [raceInfo, setRaceInfo] = useState({
    id: null,
    status: null,
    fightNumber: null,
  });
  const [megaJackpotPrize, setMajorJackpotPrize] = useState(0);
  const [didWin, setDidWin] = useState({
    winning_amount: 0,
    win_status: '',
    playerid: 0,
  });
  const [didWinMajorGrand, setDidWinMajorGrand] = useState({
    winning_amount: 0,
    win_status: '',
    winGrand: false,
    winMajor: false,
    playerid: 0,
  });

  const [grandJackpot, setGrandJackpot] = useState({});
  const [megaJackpot, setMegaJackpot] = useState({});
  const [buttonsArray, setButtonsArray] = useState([]);
  const [buttonsMajorJackpot, setButtonsMajorJackpot] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [socketStatus, setSocketStatus] = useState('');
  const {
    isOpen: isOpenMega,
    onOpen: onOpenMega,
    onClose: onCloseMega,
  } = useDisclosure();

  const handleBetButtonClick = button => {
    if (notAllowedBetting.has(raceInfo?.status)) {
      return;
    }
    setSelectedBetButton(button);
    onOpen();
  };

  // const handleBetMajorClick = button => {
  //   if (notAllowedMajorBetting.has(raceInfo?.status)) {
  //     return;
  //   }
  //   onOpenMega();
  // };

  const getPoints = useCallback(async () => {
    await axiosPrivate
      .get('points')
      .then(({ data: { data } }) => {
        setAuth(prev => ({ ...prev, points: data?.[0]?.points ?? 0 }));
      })
      .catch(err => console.log(apiErrorHandler(err)));
  }, [axiosPrivate, setAuth]);

  const getBetStatus = useCallback(async () => {
    const params = new URLSearchParams({ event: eventId, race: raceId });

    await axiosPrivate
      .get('bet/regular_bet/status', { params })
      .then(({ data: { data } }) => {
        setDidWin(data);
      })
      .catch(err => console.log(apiErrorHandler(err)));
  }, [axiosPrivate, eventId, raceId]);

  const getBetStatusMajor = useCallback(async () => {
    const params = new URLSearchParams({ event: eventId });

    await axiosPrivate
      .get('bet/major_bet/status', { params })
      .then(({ data: { data } }) => {
        setDidWinMajorGrand({ ...data, winMajor: data?.win_status === 'WIN' });
        getPoints();
      })
      .catch(err => console.log(apiErrorHandler(err)));
  }, [axiosPrivate, eventId, getPoints]);

  const getBetStatusGrand = useCallback(async () => {
    const params = new URLSearchParams({ event: eventId });

    await axiosPrivate
      .get('bet/grand_bet/status', { params })
      .then(({ data: { data } }) => {
        setDidWinMajorGrand({ ...data, winGrand: data?.win_status === 'WIN' });
        getPoints();
      })
      .catch(err => console.log(apiErrorHandler(err)));
  }, [axiosPrivate, eventId, getPoints]);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    async function getEventsInfo() {
      const params = raceId
        ? new URLSearchParams({ eventId, raceId })
        : new URLSearchParams({ eventId });
      await axiosPrivate
        .get('event/race/player/enter', { params, signal: controller.signal })
        .then(({ data }) => {
          const {
            data: { event, race },
          } = data;

          if (race.length <= 0) {
            navigate('jackpot', { replace: true });
            return;
          }

          if (
            race?.[0]?.status === 'DONE' ||
            race?.[0]?.status === 'PENDING' ||
            race?.[0]?.status === 'CANCEL'
          ) {
            getBetStatus();
          }
          setDidWinMajorGrand({
            winMajor: false,
            winGrand: false,
            win_status: null,
            winning_amount: 0,
          });
          getPoints();

          // if (race?.[0]?.status === 'DONE' && race?.[0]?.declare_win) {
          //   getBetStatus();
          // }

          isMounted && setEventInfo(event);
          isMounted && setLoading(false);
          isMounted && setRaceInfo(race?.[0] || {});
        })
        .catch(err => {
          console.error(
            err.response?.data?.msg || 'Failed to retrieve event details.'
          );
        });
    }

    getEventsInfo();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [
    raceId,
    eventId,
    axiosPrivate,
    navigate,
    refreshRaceInfo,
    getPoints,
    getBetStatus,
  ]);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    async function getButtons() {
      const params2 = new URLSearchParams({
        event: eventInfo.id,
        race: raceInfo.id,
      });

      await axiosPrivate
        .get('event/button', { params: params2, signal: controller.signal })
        .then(res => {
          return {
            announcement: res.data?.announcement,
            buttons: res.data?.data || [],
            pickColor: res.data?.pick_color || [],
            grandJackpotPrize: res.data?.grand_jackpot_prize,
            megaJackpotPrize: res.data?.mega_jackpot_prize,
            majorJackpotPrize: res.data?.major_jackpot_prize,
            historyPercentage: res?.data?.button_percentage || [],
          };
        })
        .then(
          ({
            buttons,
            pickColor,
            grandJackpotPrize,
            megaJackpotPrize,
            majorJackpotPrize,
            announcement,
            historyPercentage,
          }) => {
            const _buttonsArray = buttons.filter(
              item => item?.button_name !== 'MEGA_JACKPOT'
            );
            const _buttonsObj = buttons.filter(
              item => item?.button_name === 'MEGA_JACKPOT'
            );
            isMounted && setAnnouncement(announcement || null);
            isMounted && setButtonsMajorJackpot(..._buttonsObj);
            isMounted && setButtonsArray(_buttonsArray);
            isMounted && setMajorJackpotPrize(majorJackpotPrize);
            isMounted && setGrandJackpot(grandJackpotPrize);
            isMounted && setMegaJackpot(megaJackpotPrize);
            setHistoryPercentage(historyPercentage);
          }
        )
        .catch(err => {
          console.error(
            err.response?.data?.msg || 'Failed to retrieve event details.'
          );
        });
    }

    eventInfo.id && raceInfo.id && getButtons();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [eventInfo.id, raceInfo.id, axiosPrivate, refreshRaceBet]);

  /* SOCKET */
  useEffect(() => {
    socketPlayer.io.opts.query = {
      ertyuhgvbniuhgbn: MERCHANTID,
      ertyuihgh: eventId,
      msdfewaer: SITEID,
      usfsafsdnla: auth.user.username,
      isafdsafasp: auth.roles,
    };

    if (eventId && auth.user.username && auth.roles) {
      socketPlayer.open();
    }

    socketPlayer.on('connect', () => {
      // setSocketDisconnected(false);
      socketPlayer.sendBuffer = [];
    });

    socketPlayer.on('reconnecting', () => {
      // setRefreshBets(prev => !prev)
    });

    socketPlayer.on('connect_error', () => {
      // setSocketDisconnected(true);
    });

    // socketPlayer.on(PLAYERCOUNT, data => {
    //   console.log(data);
    // });

    socketPlayer.on(CURRENT_DECK_STATUS, data => {
      // {"eventId":"103","raceId":941}
      if (data?.declareType === 'MAJOR') {
        getBetStatusMajor();
        return;
      }
      if (data?.declareType === 'GRAND') {
        getBetStatusGrand();
        return;
      }

      setSocketStatus(data?.status || '');
      setRefreshRaceInfo(prev => !prev);
      navigate(`/console/${data?.eventId}/${data.raceId}`);

      // if (data?.raceId) {
      //   setRaceInfo(prev => ({ ...prev, id: data.raceId }));
      // }
    });

    socketPlayer.on(BETPLACED, data => {
      setRefreshRaceBet(prev => !prev);
    });

    return () => {
      socketPlayer.removeAllListeners();
      socketPlayer.disconnect();
    };
  }, [
    auth.user.username,
    eventId,
    auth.roles,
    navigate,
    getBetStatusGrand,
    getBetStatusMajor,
  ]);
  /* END SOCKET */

  useEffect(() => {
    if (notAllowedBetting.has(raceInfo?.status)) {
      onClose();
    }
  }, [raceInfo?.status, onClose]);

  if (!eventInfo?.event_name && !loading) {
    return (
      <Box
        w={['full', 'xl']}
        h={['calc(100vh - 60px)', 'sm']}
        bgSize={['100% 100%']}
        bgRepeat="no-repeat"
        bgImage={["url('/COMINGSOON-MOBILE.jpg')", 'url(/COMINGSOON.jpg)']}
        m="auto"
      ></Box>
    );
  }

  return (
    <Box pb="10" minH="calc(100vh - 60px)" w="full" pt={2}>
      <PlayerFund />
      <HStack justifyContent="space-between">
        <Heading noOfLines={1} as={'h2'} size={'md'} p={'2'} color="gold">
          {eventInfo?.event_name || 'EVENT NAME'}
        </Heading>
        <Button
          bg="transparent"
          color="red.500"
          fontWeight="bold"
          size={'sm'}
          onClick={() => navigate('/home')}
          borderRadius="0"
        >
          BACK
        </Button>
      </HStack>
      {announcement ? (
        <Box hideBelow="md" mt={1}>
          <Announcement announcement={announcement} />
        </Box>
      ) : null}

      <Stack direction={['column', 'row']} gap={[0, '0.5rem']}>
        <Box w="full" mt={[0, 2]}>
          <Box w="full" position="relative" overflow="hidden">
            <VideoPlayer
              videoType={eventInfo?.video_type}
              videoSrc={eventInfo?.video}
            />
            {socketStatus === 'OPEN' ? <StartImg /> : null}
            {didWin?.win_status === 'WIN' ? <WinImg didWin={didWin} /> : null}
            {didWinMajorGrand?.win_status === 'WIN' ? (
              <GrandMajorWinImg didWinMajorGrand={didWinMajorGrand} />
            ) : null}
          </Box>
          {announcement ? (
            <Box hideFrom="md">
              <Announcement announcement={announcement} />
            </Box>
          ) : null}
        </Box>

        <Box width={['full', '40%']} mt={[0, 2]}>
          <HStack
            alignItems="center"
            mt={[2, 0]}
            mb={2}
            mx={2}
            border="3px solid goldenrod"
          >
            <Box
              py={2}
              px={4}
              borderStartRadius="sm"
              borderRight="3px solid goldenrod"
            >
              Race #:{' '}
              <Text as="span" fontSize="xl" fontWeight="bold">
                {raceInfo?.fightNumber}
              </Text>
            </Box>
            <Box
              textAlign="center"
              flex={1}
              fontSize="xl"
              fontWeight="bold"
              p={2}
              borderEndRadius="sm"
            >
              <Status
                status={raceInfo?.status}
                declaredWin={raceInfo?.declare_win}
              />
            </Box>
          </HStack>
          {/* BET BUTTONS */}
          <Box
            pb={2}
            px={1}
            pt={2}
            // border="10px solid black"
            // sx={{
            //   borderImageSource: 'url("/border2.png")',
            //   borderImageRepeat: 'round',
            //   borderImageSlice: 80,
            // }}
            // px={8}
            // py={10}
            // bgImage={bgConsole}
            // bgRepeat="no-repeat"
            // bgSize="100% 100%"
            // bgPos="center"
          >
            <Grid templateColumns="repeat(3, 1fr)" gap={1}>
              {buttonsArray.map(button => (
                <GridItem key={button?.id} w="full">
                  <BetButton
                    {...button}
                    onClick={handleBetButtonClick.bind(this, button)}
                  />
                </GridItem>
              ))}
            </Grid>
            <HStack alignItems="stretch" mt={2} px={1}>
              <BetButtonGrandJackpot
                {...grandJackpot}
                event={eventInfo}
                refreshData={refreshRaceBet}
              />
              <BetButtonMegaJackpot
                {...megaJackpot}
                event={eventInfo}
                refreshData={refreshRaceBet}
              />
            </HStack>
            {/* <HStack alignItems="stretch" mt={2} px={1}>
              <BetButtonMajorJackpot
                majorJackpotPrize={majorJackpotPrize}
                {...buttonsMajorJackpot}
                onClick={handleBetMajorClick}
              />
            </HStack> */}
          </Box>
        </Box>
      </Stack>
      <Box mt="2" px={2}>
        <Tally2 raceHistory={historyPercentage} />
      </Box>
      <RaceHistory eventId={eventInfo?.id} refreshRaceInfo={refreshRaceInfo} />
      <Box mt={4} />
      <PagcorLogo />
      <ConfirmBetDialog
        {...selectedBetButton}
        race={raceInfo}
        eventId={eventInfo?.id}
        onClose={onClose}
        isOpen={isOpen}
        setRefreshRaceBet={setRefreshRaceBet}
        socketPlayer={socketPlayer}
        getPoints={getPoints}
      />
      <ConfirmMajorJackpotBet
        race={raceInfo}
        eventId={eventInfo?.id}
        onClose={onCloseMega}
        isOpen={isOpenMega}
        setRefreshRaceBet={setRefreshRaceBet}
        socketPlayer={socketPlayer}
        getPoints={getPoints}
      />
    </Box>
  );
}
const blink = keyframes`
50% { opacity: 0;}
`;

function Status({ status = '-', declaredWin = '' }) {
  const blinkAnimation = `${blink} 1s linear infinite`;

  let _status = status?.replaceAll(/_/gi, ' ');
  if (status === 'LOCK' || status === 'DONE') {
    _status = 'CLOSE';
  }

  const colors = () => {
    let color = 'white';
    let shadow = 'black';
    switch (status) {
      case 'LAST_CALL':
        color = 'white';
        shadow = 'green';
        break;
      case 'OPEN':
        color = 'cyan';
        shadow = 'blue';
        break;

      case 'CLOSE':
      case 'LOCK':
      case 'CANCEL':
      case 'PENDING':
        color = 'white';
        shadow = 'red';
        break;

      default:
        color = 'white';
        break;
    }

    return { color, shadow };
  };

  if (status === 'DONE' && declaredWin) {
    return (
      <HStack justifyContent="center">
        <Text
          color={BetButtonColor?.[declaredWin]?.textColor?.win}
          textShadow={`0 0 5px ${BetButtonColor?.[declaredWin]?.textColor?.shadow},0 0 19px ${BetButtonColor?.[declaredWin]?.textColor?.shadow},0 0 40px ${BetButtonColor?.[declaredWin]?.textColor?.shadow}`}
          letterSpacing={4}
        >
          {declaredWin}{' '}
        </Text>
        <Text color="white" letterSpacing={4}>
          WINS
        </Text>
      </HStack>
    );
  }
  if (status === 'LAST_CALL') {
    return (
      <Text
        animation={blinkAnimation}
        color={colors().color}
        textShadow={`0 0 5px ${colors().shadow},0 0 19px ${
          colors().shadow
        },0 0 40px ${colors().shadow}`}
        letterSpacing={4}
      >
        {_status}
      </Text>
    );
  }

  return (
    <Text
      color={colors().color}
      textShadow={`0 0 5px ${colors().shadow},0 0 19px ${
        colors().shadow
      },0 0 40px ${colors().shadow}`}
      letterSpacing={4}
    >
      {_status}
    </Text>
  );
}
