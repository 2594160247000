const amountButtons = [
  { label: 5, value: 5 },
  { label: 10, value: 10 },
  { label: 20, value: 20 },
  { label: 50, value: 50 },
  { label: 100, value: 100 },
  { label: 200, value: 200 },
  { label: 500, value: 500 },
  { label: '1K', value: 1e3 },
  { label: '2K', value: 2e3 },
];

export default amountButtons;
