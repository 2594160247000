import {
  Box,
  Button,
  Center,
  Circle,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import apiErrorHandler from '../../api/apiErrorHandler';
import { statusColor } from '../../helpers/statusColor';
import { CURRENT_DECK_STATUS } from '../../helpers/constants';

export default function DeclareMegaGrand({
  eventId,
  currentRace,
  eventRaceHistory,
  socketDeclarator,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [declareType, setDeclareType] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const axiosPrivate = useAxiosPrivate();
  const toast = useToast();

  const handleButtonClick = type => {
    setDeclareType(type);
    onOpen();
  };

  const handleDeclare = async () => {
    toast.closeAll();

    if (!declareType) {
      return;
    }

    let url =
      declareType === 'MAJOR'
        ? 'declarator/race/mega_jackpot/declare'
        : 'declarator/race/grand_jackpot/declare';
    setIsLoading(true);

    await axiosPrivate
      .post(url, {
        raceId: currentRace?.id,
        eventId,
      })
      .then(({ data }) => {
        console.log(data);
        toast({
          title: 'Success',
          description:
            data?.msg || `${declareType} Jackpot declared successfully.`,
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      })
      .catch(err => {
        const msg = apiErrorHandler(err);
        console.error(msg);
        toast({
          title: 'Declare Failed.',
          description: msg,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      })
      .finally(() => {
        socketDeclarator.emit(CURRENT_DECK_STATUS, {
          eventId,
          raceId: currentRace?.id,
          declareType,
        });
        onClose();
        setIsLoading(false);
      });
  };

  return (
    <>
      <Box>
        <Button
          w="full"
          size="md"
          variant="solid"
          colorScheme="blue"
          isDisabled={isLoading}
          mb={4}
          // onClick={handleDeclare.bind(this, 'MAJOR')}
          onClick={handleButtonClick.bind(this, 'MAJOR')}
        >
          Declare MAJOR Jackpot
        </Button>
        <Button
          w="full"
          size="md"
          variant="solid"
          colorScheme="blue"
          isDisabled={isLoading}
          onClick={handleButtonClick.bind(this, 'GRAND')}
        >
          Declare GRAND Jackpot
        </Button>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Confirm {declareType === 'MAJOR' ? 'Major' : 'Grand'} Jackpot?
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Table>
              <Thead>
                <Tr>
                  <Th>Race #</Th>
                  <Th textAlign="center">Wining Color</Th>
                </Tr>
              </Thead>
              <Tbody>
                {eventRaceHistory.map((item, index) => (
                  <Tr key={`outer-${item?.raceDetails?.f_no}`}>
                    {declareType === 'MAJOR' && index > 5 ? null : (
                      <>
                        <Td>{item?.raceDetails?.f_no}</Td>

                        <Td>
                          <Center width="full">
                            {item?.raceDetails?.declare_win?.color ? (
                              <>
                                {(
                                  item?.raceDetails?.declare_win?.number || []
                                ).map(label => (
                                  <Circle
                                    cursor={'pointer'}
                                    key={`pc-${item?.raceDetails?.f_no}-${index}`}
                                    h="25px"
                                    w="25px"
                                    // bg={item?.raceDetails?.declare_win}
                                    // borderColor="gray.200"
                                    // borderWidth={'1px'}
                                    bgImage={`/150x150pxballs/BALLS-${item?.raceDetails?.declare_win?.color}.png`}
                                    bgSize="contain"
                                  ></Circle>
                                ))}
                              </>
                            ) : (
                              <Text
                                fontSize="xs"
                                color={statusColor(item?.raceDetails?.status)}
                              >
                                {item?.raceDetails?.status}
                              </Text>
                            )}
                          </Center>
                        </Td>
                      </>
                    )}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="green"
              onClick={handleDeclare}
              mr={4}
              minW={100}
            >
              Declare
            </Button>
            <Button colorScheme="blue" mr={3} onClick={onClose} minW={100}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
